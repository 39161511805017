import * as React from 'react';
import Select, { type GroupBase, type StylesConfig } from 'react-select';

interface Props<T> {
  value: string | null;
  valueMapper: (a: T) => string;
  titleMapper: (a: T) => string;
  options: Array<T>;
  onChange: (item: T | null) => void;
  placeholder?: string;
  className?: string;
  isClearable?: boolean;
  disabled?: boolean;
}

export default class Dropdown<T> extends React.Component<Props<T>> {
  render() {
    const {
      options,
      className,
      value,
      onChange,
      placeholder,
      valueMapper,
      titleMapper,
      isClearable,
      disabled,
    } = this.props;

    const selectedValue = options.find(x => valueMapper(x) === value) || null;

    const customStyles: StylesConfig<T, false, GroupBase<T>> = {
      option: base => ({
        ...base,
        padding: 8,
      }),
      control: base => ({
        ...base,
        height: 32,
        minHeight: 32,
        borderRadius: 0,
        border: '1px solid #bbbcc0',
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 4,
      }),
      clearIndicator: base => ({
        ...base,
        padding: 4,
      }),
      valueContainer: base => ({
        ...base,
        padding: '0px 6px',
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
    };

    return (
      <Select<T>
        placeholder={placeholder}
        getOptionLabel={titleMapper}
        getOptionValue={valueMapper}
        value={selectedValue}
        options={options}
        onChange={onChange}
        className={className}
        isClearable={isClearable}
        isDisabled={disabled}
        styles={customStyles}
      />
    );
  }
}
