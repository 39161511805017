import actionCreatorFactory from 'typescript-fsa';
import type { Member } from 'types/api';

const actionCreator = actionCreatorFactory('skaite/shared');
export const receiveMembers = actionCreator<Array<Member>>('RECEIVED_MEMBERS');
export const receiveSingleMember = actionCreator<Member>(
  'RECEIVED_SINGLE_MEMBER',
);

export interface State {
  members: Array<Member>;
}
export const initialState: State = {
  members: [],
};
