import * as React from 'react';

import Dropdown from './Dropdown';
import type { Member } from '../../types/api';
import { useCallback, useMemo } from 'react';
import { calculateMemberAge } from 'apps/reporting/logic/calculateCosts';
import { DateTime } from 'luxon';

interface Props {
  value: number | null;
  members: Array<Member>;
  onChange: (memberId: number | null) => void;
  className?: string;
  isClearable?: boolean;
  disabled?: boolean;
  minAge?: number;
}

const END_OF_YEAR = DateTime.now().endOf('year').toISODate();
const MemberSelect = (props: Props) => {
  const { members, className, value, onChange, isClearable, disabled, minAge } =
    props;

  const memberOptions = useMemo(
    () =>
      members
        .filter(member => {
          if (value === member.id) {
            return true;
          }
          if (
            minAge != null &&
            (calculateMemberAge(member, END_OF_YEAR) ?? 99) < minAge
          ) {
            return false;
          }
          return !member.deleted && member.date_of_death == null;
        })
        .map(member => {
          let label = member.name;
          if (members.filter(m => m.name === member.name).length > 1) {
            const year = member.date_of_birth.substring(0, 4);
            label = `${member.name} (${year})`;
          }
          return {
            value: member.id,
            label,
            dropdownValue: String(member.id),
          };
        }),
    [members, value, minAge],
  );

  type MemberOption = (typeof memberOptions)[number];
  const handleChange = useCallback(
    (member: MemberOption | null) => {
      onChange(member?.value ?? null);
    },
    [onChange],
  );

  return (
    <Dropdown
      placeholder="Välj medlem..."
      value={value != null ? String(value) : null}
      options={memberOptions}
      onChange={handleChange}
      titleMapper={x => x.label}
      valueMapper={x => x.dropdownValue}
      className={className}
      isClearable={isClearable}
      disabled={disabled}
    />
  );
};

export default MemberSelect;
