import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import Calendar from 'apps/calendar/Calendar';
import ReportingApp from 'apps/reporting/ReportingApp';
import * as Sentry from '@sentry/browser';
import store, { type AppDispatch } from 'shared/setup/store';
import { Provider, useDispatch } from 'react-redux';
import { fetchMembers } from 'shared/state/shared.actions';
import MembersApp from 'apps/members/MembersApp';
import ReactModal from 'react-modal';

import '../classical';

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: 'https://01d2c6c516694f349eeb823ca2af4e90@sentry.io/1225923',
    environment: import.meta.env.MODE,
  });
}

const GlobalFetches: React.FC<{ children: React.ReactElement }> = props => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMembers());
  }, [dispatch]);
  return <>{props.children}</>;
};

ReactModal.setAppElement('#wrap');

function wrap(element: React.ReactElement) {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <GlobalFetches>{element}</GlobalFetches>
      </Provider>
    </React.StrictMode>
  );
}

// biome-ignore lint/suspicious/noExplicitAny: type barrier
const componentMap: Record<string, React.ComponentType<any>> = {
  Calendar,
  ReportingApp,
  MembersApp,
};

for (const el of document.getElementsByClassName('react-mount')) {
  const component = el.getAttribute('data-component');
  if (!component) {
    throw new Error('Missing data-component attribute');
  }
  const props = JSON.parse(el.getAttribute('data-props') ?? '{}');
  const Component = componentMap[component];
  if (!Component) {
    throw new Error(`Unknown component ${component}`);
  }
  createRoot(el).render(wrap(<Component {...props} />));
}
