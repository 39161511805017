import * as React from 'react';

import { calculateCosts } from '../logic/calculateCosts';

import type { Member, Booking } from 'types/api';
import sortBy from 'lodash/sortBy';

interface Props {
  members: Array<Member>;
  bookings: Array<Booking>;
}

interface Statistics {
  name: string;
  numNights: number;
  numPointsAccrued: number;
  debtAccrued: number;
  numBoatRides: number;
}
const initialStatistics = (name: string): Statistics => ({
  name,
  numNights: 0,
  numPointsAccrued: 0,
  debtAccrued: 0,
  numBoatRides: 0,
});

function mapStatisticsMapEntry<TKey>(
  map: Map<TKey, Statistics>,
  key: TKey,
  name: string,
  fn: (statistics: Statistics) => void,
) {
  let entry = map.get(key);
  if (!entry) {
    entry = initialStatistics(name);
    map.set(key, entry);
  }
  fn(entry);
}

function summarize(map: Map<unknown, Statistics>): Statistics {
  const total = initialStatistics('Total');
  for (const statistics of map.values()) {
    total.numNights += statistics.numNights;
    total.debtAccrued += statistics.debtAccrued;
    total.numPointsAccrued += statistics.numPointsAccrued;
    total.numBoatRides += statistics.numBoatRides;
  }
  return total;
}

export default class ReportStatistics extends React.Component<Props> {
  render() {
    const { bookings, members } = this.props;
    const getName = (id: number) =>
      members.find(member => member.id === id)?.name ?? `!Medlem#${id}`;
    const statisticsByMember = new Map<number, Statistics>();
    const statisticsByGuest = new Map<string, Statistics>();

    for (const booking of bookings) {
      const report = booking.report;
      if (!report) {
        continue;
      }
      const costs = calculateCosts(
        report.report_participants,
        report.accompanying_member_id,
        booking.num_free_nights,
      );
      if (booking.member_id) {
        mapStatisticsMapEntry(
          statisticsByMember,
          booking.member_id,
          getName(booking.member_id),
          stats => {
            stats.numPointsAccrued += costs.totalPoints;
            stats.debtAccrued += costs.totalCost;
          },
        );
        for (const participant of report.report_participants) {
          if (participant.member_id) {
            mapStatisticsMapEntry(
              statisticsByMember,
              participant.member_id,
              getName(participant.member_id),
              stats => {
                stats.numNights += participant.num_nights;
                stats.numBoatRides += participant.num_boat_rides;
              },
            );
          } else {
            mapStatisticsMapEntry(
              statisticsByGuest,
              participant.guest_name,
              participant.guest_name ?? 'Okänd',
              stats => {
                stats.numNights += participant.num_nights;
                stats.numBoatRides += participant.num_boat_rides;
              },
            );
          }
        }
      }
    }

    const memberTotals = summarize(statisticsByMember);
    const guestTotals = summarize(statisticsByGuest);
    const membersAscending = sortBy(
      [...statisticsByMember.values()],
      s => s.name,
    );
    const guestsAscending = sortBy(
      [...statisticsByGuest.values()],
      s => s.name,
    );

    const formatStatistics = (statistics: Statistics): string => {
      return JSON.stringify(statistics);
    };

    return (
      <div className="statistics">
        <h2>Medlemmar</h2>
        <h3>Totalt: {formatStatistics(memberTotals)}</h3>
        <pre>{JSON.stringify(membersAscending, null, 2)}</pre>

        <h2>Gäster</h2>
        <h3>Totalt: {formatStatistics(guestTotals)}</h3>
        <pre>{JSON.stringify(guestsAscending, null, 2)}</pre>
      </div>
    );
  }
}
