function autoFocusFirstEmpty(ids: Array<string>) {
  for (const id of ids) {
    const element = document.getElementById(id);
    if (!(element instanceof HTMLInputElement)) {
      continue;
    }
    if (element.value === '') {
      element.focus();
      break;
    }
  }
}

function applySmartAutoFocus() {
  autoFocusFirstEmpty(['user_session_login', 'user_session_password']);
}

applySmartAutoFocus();
