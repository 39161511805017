// Prefill in file name as title
const document_file = document.getElementById('document_file');
const document_title = document.getElementById('document_title');
if (document_file != null) {
  document_file.addEventListener('change', e => {
    const input = e.currentTarget;
    if (
      !(input instanceof HTMLInputElement) ||
      document_title == null ||
      !(document_title instanceof HTMLInputElement) ||
      document_title.value.length > 0
    ) {
      return;
    }
    let name: string | undefined = input.files?.[0]?.name;
    name ??= input.value.split('/').pop()?.split('\\').pop() ?? '';
    name = name.replace(/\.[^/.]+$/, '');
    name = name.replace(/[_-]+/g, ' ');

    document_title.value = name;
  });
}
