import classNames from 'classnames';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from 'shared/api';
import { ValidationError } from 'shared/api/base';
import MemberSelect from 'shared/components/MemberSelect';
import Modal from 'shared/components/Modal';
import useConfirmationModal from 'shared/hooks/useConfirmationModal';
import { getViableRelationsForMember } from 'shared/state/shared.selectors';
import { receiveSingleMember } from 'shared/state/shared.types';
import type { MemberPayload } from 'types/api';
import styles from './MemberEditModal.module.scss';
import type { ThunkDispatch } from 'shared/setup/store';

const MemberEditModal = (props: {
  member: MemberPayload;
  isModerator: boolean;
  onClose: () => void;
}) => {
  const { onClose, isModerator } = props;
  const [draft, setDraft] = useState<MemberPayload>(() => props.member);
  const [errors, setErrors] = useState<Array<string> | null>(null);
  const dispatch = useDispatch<ThunkDispatch>();
  const handleSave = useCallback(async () => {
    try {
      setErrors(null);
      const updatedMember = await api.members.saveMember(draft);
      dispatch(receiveSingleMember(updatedMember));
      onClose();
    } catch (e) {
      if (e instanceof ValidationError) {
        setErrors(e.messages);
      } else {
        console.error(e);
      }
    }
  }, [draft, dispatch, onClose]);

  const handleDelete = useCallback(async () => {
    if (draft.id == null) {
      return;
    }
    setErrors(null);
    try {
      const updatedMember = await api.members.deleteMember(draft.id);
      dispatch(receiveSingleMember(updatedMember));
      onClose();
    } catch (e) {
      if (e instanceof ValidationError) {
        setErrors(e.messages);
      } else {
        console.error(e);
      }
    }
  }, [draft, onClose, dispatch]);
  const deleteConfirm = useConfirmationModal({
    onConfirm: handleDelete,
    confirmButtonLabel: `Ta bort ${draft.first_name} ${draft.last_name}`,
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSave();
    },
    [handleSave],
  );

  const handleDraftChange =
    <TField extends keyof MemberPayload>(field: TField) =>
    (e: React.FormEvent<HTMLInputElement>) => {
      setDraft({ ...draft, [field]: e.currentTarget.value });
    };

  const handleChangeIsPaying = (e: React.FormEvent<HTMLInputElement>) => {
    setDraft({ ...draft, is_paying: e.currentTarget.checked });
  };

  const [relation, setRelation] = useState<'child' | 'spouse'>(() =>
    draft.spouse_id != null ? 'spouse' : 'child',
  );
  const onCheckRelationCheckbox =
    (relation: 'child' | 'spouse') =>
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.checked) {
        setDraft(draft => ({
          ...draft,
          parent_id: null,
          spouse_id: null,
        }));
        setRelation(relation);
      }
    };
  const memberOptions = useSelector(getViableRelationsForMember)(
    draft.id ?? -1,
  );
  const onChangeRelation = (id: number | null) => {
    setDraft(draft => ({
      ...draft,
      [relation === 'child' ? 'parent_id' : 'spouse_id']: id,
    }));
  };
  const relationId =
    (relation === 'child' ? draft.parent_id : draft.spouse_id) ?? null;

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit} className={styles.root}>
        <div className={styles.header}>
          {draft.id == null
            ? 'Lägg till medlem'
            : `Redigera ${draft.first_name}`}
        </div>
        <div className={styles.row}>
          <div className={styles.columns}>
            <div className={styles.column}>
              <label htmlFor="first_name" className="bold">
                Förnamn
              </label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                required
                value={draft.first_name ?? ''}
                onChange={handleDraftChange('first_name')}
              />
            </div>
            <div className={styles.column}>
              <label htmlFor="last_name" className="bold">
                Efternamn
              </label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                required
                value={draft.last_name ?? ''}
                onChange={handleDraftChange('last_name')}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.columns}>
            <div className={styles.column}>
              <label htmlFor="email" className="bold">
                E-mail
              </label>
              <input
                type="text"
                name="email"
                id="email"
                value={draft.email ?? ''}
                onChange={handleDraftChange('email')}
              />
            </div>
            <div className={styles.column}>
              <label htmlFor="date_of_birth" className="bold">
                Födelsedatum
              </label>
              <input
                type="text"
                name="date_of_birth"
                id="date_of_birth"
                placeholder="åååå-mm-dd"
                value={draft.date_of_birth ?? ''}
                onChange={handleDraftChange('date_of_birth')}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.columns}>
            <div className={styles.column}>
              <input
                type="checkbox"
                id="is_paying"
                checked={!!draft.is_paying}
                onChange={handleChangeIsPaying}
              />
              <label htmlFor="is_paying" className="bold inline">
                Är betalande medlem
              </label>
            </div>
            {isModerator && (
              <div className={styles.column}>
                <label htmlFor="date_of_death" className="bold">
                  Dödsdatum
                </label>
                <input
                  type="text"
                  name="date_of_death"
                  id="date_of_death"
                  placeholder="åååå-mm-dd"
                  value={draft.date_of_death ?? ''}
                  onChange={handleDraftChange('date_of_death')}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.row}>
          <span>
            <input
              type="checkbox"
              id="relation_child"
              checked={relation === 'child'}
              onChange={onCheckRelationCheckbox('child')}
            />
            <label htmlFor="relation_child" className="bold inline">
              Barn
            </label>
          </span>{' '}
          /{' '}
          <span>
            <input
              type="checkbox"
              id="relation_spouse"
              checked={relation === 'spouse'}
              onChange={onCheckRelationCheckbox('spouse')}
            />
            <label htmlFor="relation_spouse" className="bold inline">
              Partner
            </label>
          </span>{' '}
          till{' '}
          <MemberSelect
            members={memberOptions}
            onChange={onChangeRelation}
            value={relationId}
            isClearable
          />
        </div>
        {errors != null && (
          <div className={classNames(styles.row, styles.errors)}>
            <h3>{errors.length} fel förhindrade sparning:</h3>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        <div className={classNames(styles.buttonRow, styles.row)}>
          <button type="submit" className="btn btn-blue">
            {draft.id == null ? 'Skapa ny medlem' : 'Spara'}
          </button>
          <button onClick={onClose} type="button" className="btn">
            Avbryt
          </button>
        </div>
        {draft.id != null && isModerator && (
          <div
            className={classNames(
              styles.buttonRow,
              styles.row,
              styles.dangerZone,
            )}
          >
            <button
              type="button"
              className="btn btn-red"
              onClick={deleteConfirm.trigger}
            >
              Ta bort
            </button>
            {deleteConfirm.render()}
          </div>
        )}
      </form>
    </Modal>
  );
};

export default MemberEditModal;
