import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMembers } from 'shared/state/shared.selectors';
import BookingSummary from './components/BookingSummary';
import ReportingForm from './components/ReportingForm';
import PaymentSummary from './components/PaymentSummary';
import ReportSummary from './components/ReportSummary';
import type { Booking, BookingReport, Member } from 'types/api';
import { useParams } from 'react-router-dom';
import { createInitialParticipantsForBooking } from './logic/reportDraft.logic';
import {
  useGetBookingByIdQuery,
  useGetEventsByYearQuery,
  useSaveBookingReportMutation,
} from './state/reporting.slice';
import { enrichBooking } from './logic/factories';
import { useEffectOnValueChange } from 'shared/hooks/useEffectOnValueChange';

const SingleBookingRoute: React.FC = () => {
  const members = useSelector(getMembers);
  const [reportDraft, setReportDraft] = useState<BookingReport | null>(null);

  const { bookingId: bookingIdRaw } = useParams<{ bookingId: string }>();

  const bookingId = Number(bookingIdRaw);
  useEffectOnValueChange(bookingId, () => {
    setReportDraft(null);
  });

  const { data: rawBooking, isFetching } = useGetBookingByIdQuery(bookingId);
  const { data: eventsFrom } = useGetEventsByYearQuery(
    rawBooking?.date_to_year ?? 0,
    { skip: !rawBooking },
  );
  const { data: eventsTo } = useGetEventsByYearQuery(
    rawBooking?.date_to_year ?? 0,
    {
      skip:
        !rawBooking || rawBooking.date_from_year === rawBooking.date_to_year,
    },
  );
  const events = useMemo(
    () => (eventsFrom ?? []).concat(eventsTo ?? []),
    [eventsFrom, eventsTo],
  );
  const booking = useMemo(
    () =>
      rawBooking &&
      enrichBooking(rawBooking, {
        events,
      }),
    [rawBooking, events],
  );

  useEffectOnValueChange(
    booking,
    () => {
      if (booking != null && booking.report == null) {
        setReportDraft(createDraftForBooking(booking, members));
      }
    },
    true,
  );

  const handleEdit = useCallback(() => {
    if (booking != null) {
      setReportDraft(createDraftForBooking(booking, members));
    }
  }, [booking, members]);
  const [saveReport] = useSaveBookingReportMutation();
  const handleSave = useCallback(() => {
    if (reportDraft != null) {
      saveReport(reportDraft).then(() => {
        setReportDraft(null);
        window.scrollTo(0, 0);
      });
    }
  }, [reportDraft, saveReport]);
  const handleCancel = useCallback(() => {
    setReportDraft(null);
  }, []);
  const handleUpdateDraft = useCallback((draft: Partial<BookingReport>) => {
    setReportDraft(reportDraft => {
      if (reportDraft == null) {
        return null;
      }
      return {
        ...reportDraft,
        ...draft,
      };
    });
  }, []);

  let title = `Rapport #${bookingId}`;
  let top: React.ReactElement | null = null;
  let main: React.ReactElement | null = null;

  if (booking != null) {
    top = <BookingSummary booking={booking} members={members} />;
    title = 'Rapportera kalenderbokning';
    if (reportDraft != null) {
      if (booking.report != null) {
        title = 'Ändra rapport';
      }
      main = (
        <ReportingForm
          bookingId={bookingId}
          onSave={handleSave}
          onCancel={handleCancel}
          onUpdateDraft={handleUpdateDraft}
          booking={booking}
          members={members}
          report={reportDraft}
        />
      );
    } else if (booking.report != null) {
      main = (
        <div className="units-row">
          <div className="unit-75">
            <ReportSummary
              members={members}
              participants={booking.report.report_participants}
              comment={booking.report.comment}
              accompanyingMemberId={booking.report.accompanying_member_id}
              numFreeNights={booking.num_free_nights}
            />
            <button className="btn btn-blue" type="submit" onClick={handleEdit}>
              Ändra
            </button>
          </div>
          <div className="unit-25">
            <PaymentSummary
              booking={booking}
              report={booking.report}
              members={members}
            />
          </div>
        </div>
      );
    }
  }

  const showLoading = isFetching && booking == null;

  return (
    <div>
      <h1>{title}</h1>
      {showLoading && 'Laddar...'}
      {top}
      <div className="main">{main}</div>
    </div>
  );
};

export default SingleBookingRoute;

const createDraftForBooking = (
  booking: Booking,
  members: Array<Member>,
): BookingReport => {
  let participants = createInitialParticipantsForBooking(booking, members);
  let comment = '';
  let accompanyingMemberId = null;

  const { report } = booking;
  if (report != null) {
    participants = report.report_participants;
    comment = report.comment;
    accompanyingMemberId = report.accompanying_member_id;
  }

  const reportDraft: BookingReport = {
    id: booking.report?.id ?? null,
    booking_id: booking.id,
    report_participants: participants,
    accompanying_member_id: accompanyingMemberId,
    comment,
  };

  return reportDraft;
};
