export function parseBookingDate(date: string): Date {
  const d = new Date(date);
  d.setHours(0, 0, 0);
  return d;
}

export function getWeekNumber(date: Date): number {
  const d = new Date(date);
  d.setHours(0, 0, 0);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // Get first day of year
  const yearStart = +new Date(d.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  return Math.ceil(((+d - yearStart) / 86400000 + 1) / 7);
}

export function dateDiffDays(first: Date, second: Date) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((+second - +first) / (1000 * 60 * 60 * 24));
}

export const monthName = (zeroIndexedMonth: number): string =>
  [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
  ][zeroIndexedMonth];

export const monthAbbr = (zeroIndexedMonth: number): string =>
  [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Okt',
    'Nov',
    'Dec',
  ][zeroIndexedMonth];

export function pad2(num: number): string {
  if (num <= 9) {
    return `0${num}`;
  }
  return num.toString();
}

export function formatDate(date: Date | null): string {
  if (date == null) {
    return '';
  }
  const yyyy = date.getFullYear();
  const mm = pad2(date.getMonth() + 1).toLowerCase();
  const dd = pad2(date.getDate());
  return `${yyyy}-${mm}-${dd}`;
}

export function formatDateShort(date: Date | null): string {
  if (date == null) {
    return '';
  }
  const yyyy = date.getFullYear();
  const mm = pad2(date.getMonth() + 1).toLowerCase();
  const dd = pad2(date.getDate());
  return `${yyyy}${mm}${dd}`;
}

interface FormatRangeOptions {
  alwaysIncludeYear: boolean;
}
export function formatRange(
  d1: Date,
  d2: Date,
  options?: Partial<FormatRangeOptions>,
): string {
  const finalOptions: FormatRangeOptions = {
    alwaysIncludeYear: false,
    ...options,
  };

  // Different years
  if (d1.getFullYear() !== d2.getFullYear()) {
    return (
      `${d1.getDate()} ${monthAbbr(
        d1.getMonth(),
      ).toLowerCase()} ${d1.getFullYear()} - ` +
      `${d2.getDate()} ${monthAbbr(
        d2.getMonth(),
      ).toLowerCase()} ${d2.getFullYear()}`
    );
  }

  // Same year
  let repr: string;
  if (d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()) {
    repr = `${d1.getDate()} ${monthName(d1.getMonth()).toLowerCase()}`;
  } else if (d1.getMonth() === d2.getMonth()) {
    repr = `${d1.getDate()} - ${d2.getDate()} ${monthName(
      d1.getMonth(),
    ).toLowerCase()}`;
  } else {
    repr =
      `${d1.getDate()} ${monthAbbr(d1.getMonth()).toLowerCase()} - ` +
      `${d2.getDate()} ${monthAbbr(d2.getMonth()).toLowerCase()}`;
  }

  if (finalOptions.alwaysIncludeYear) {
    repr = `${repr} ${d1.getFullYear()}`;
  }

  return repr;
}
