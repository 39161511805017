import * as React from 'react';

import type { Booking, Member } from '../../../types/api';
import * as dateHelpers from '../../../shared/utils/dateHelpers';

interface Props {
  title?: string | null;
  members: Array<Member>;
  booking: Booking;
  onSelect?: (id: number | null) => void;
}

export default class BookingSummary extends React.Component<Props> {
  onSelect = () => {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.booking.id);
    }
  };

  render() {
    const { booking, title } = this.props;
    const classNames = ['booking-summary', `type-${booking.booking_type}`];

    const memberWhoBooked = this.props.members.find(
      m => m.id === booking.member_id,
    );
    let owner = null;
    if (memberWhoBooked != null) {
      owner = <div className="responsible">{memberWhoBooked.name}</div>;
    }

    const when = dateHelpers.formatRange(
      dateHelpers.parseBookingDate(booking.date_from),
      dateHelpers.parseBookingDate(booking.date_to),
    );

    const id = booking.id;
    if (id == null) {
      return null;
    }

    let titleElement = null;
    if (title != null) {
      titleElement = <div className="title">{title}</div>;
    }

    return (
      <div className={classNames.join(' ')} onClick={this.onSelect}>
        {titleElement}
        <div className="owner">{owner}</div>
        <span className="name">
          {booking.who} ({booking.num_people})
        </span>
        <div className="when">{when}</div>
      </div>
    );
  }
}
