import { useEffect, useRef } from 'react';

/**
 * Remembers and returns the value from the previous invocation. Useful to replicate
 * `componentDidUpdate` in a function component.
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
