import * as React from 'react';
import ReactTooltip from 'react-tooltip-lite';

type TooltipDirection =
  | 'up'
  | 'down'
  | 'left'
  | 'right'
  | 'up-start'
  | 'down-start'
  | 'left-start'
  | 'right-start'
  | 'up-end'
  | 'down-end'
  | 'left-end'
  | 'right-end';

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  tipContentClassName?: string;
  tipContentHover?: boolean;
  background?: string;
  color?: string;
  padding?: string;
  styles?: {
    [key: string]: string | number;
  };
  tagName?: string;
  direction?: TooltipDirection;
  forceDirection?: boolean;
  className?: string;
  eventOn?: string;
  eventOff?: string;
  eventToggle?: string;
  useHover?: boolean;
  useDefaultStyles?: boolean;
  isOpen?: boolean;
  hoverDelay?: number;
  arrow?: boolean;
  arrowSize?: number;
  distance?: number;
}

const Tooltip = (props: TooltipProps) => <ReactTooltip {...props} />;
export default Tooltip;
