import * as types from './shared.types';
import api from 'shared/api';

export const fetchMembers = (): Thunk<Promise<unknown>> => dispatch => {
  return api.members.fetchAll().then(members => {
    dispatch(types.receiveMembers(members));
  });
};

import type { Action } from 'redux';
import type { AppState, Thunk } from 'shared/setup/store';
