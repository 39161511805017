import React from 'react';
import classNames from 'classnames';

interface Option<T> {
  value: T;
  label: string;
}
interface Props<T> {
  options: ReadonlyArray<Option<T>>;
  onChange: (value: T) => void;
  value: T;
}
function ButtonGroupPicker<T extends string | number>({
  options,
  value,
  onChange,
}: Props<T>) {
  return (
    <span className="btn-group">
      {options.map(option => (
        <button
          type="button"
          key={option.value ?? 'null'}
          className={classNames('btn', {
            'btn-blue': value === option.value,
          })}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </button>
      ))}
    </span>
  );
}

export default ButtonGroupPicker;
