import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useParams,
} from 'react-router-dom';
import ButtonGroupPicker from 'shared/components/ButtonGroupPicker';
import { getNonDeletedMembers } from 'shared/state/shared.selectors';
import type { Member, MemberPayload } from 'types/api';
import FamilyTree from './FamilyTree';
import MemberEditModal from './MemberEditModal';
import MemberList from './MemberList';
import styles from './MembersApp.module.scss';

interface Props {
  isModerator: boolean;
}
const VIEW_MODE_OPTIONS = [
  { value: 'list', label: 'Lista' },
  { value: 'tree', label: 'Träd' },
] as const;
type ViewMode = (typeof VIEW_MODE_OPTIONS)[number]['value'];
type ViewModeLabel = Lowercase<(typeof VIEW_MODE_OPTIONS)[number]['label']>;

const MembersAppRouteWrapper = (props: Props) => {
  const { isModerator } = props;
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: '/medlemmar/:viewMode?',
          element: <MembersApp isModerator={isModerator} />,
        },
      ]),
    [isModerator],
  );
  return <RouterProvider router={router} />;
};

const MembersApp = ({ isModerator }: Props) => {
  const routeMatch = useParams<{ viewMode: ViewModeLabel }>();
  const viewModeInUrl =
    routeMatch.viewMode ?? VIEW_MODE_OPTIONS[0].label.toLocaleLowerCase();
  const viewMode =
    VIEW_MODE_OPTIONS.find(
      mode => mode.label.toLocaleLowerCase() === viewModeInUrl,
    )?.value ?? 'list';
  const navigate = useNavigate();
  const setViewMode = useCallback(
    (viewMode: ViewMode) => {
      navigate(
        `/medlemmar/${VIEW_MODE_OPTIONS.find(
          mode => mode.value === viewMode,
        )?.label.toLocaleLowerCase()}`,
        { replace: true },
      );
    },
    [navigate],
  );

  const [filterString, setFilterString] = useState('');
  const members = useSelector(getNonDeletedMembers);
  const aliveMembersCount = useMemo(
    () => members.filter(m => m.date_of_death == null).length,
    [members],
  );
  const [editingMember, setEditingMember] = useState<MemberPayload | null>(
    null,
  );
  const handleClickMember = useCallback((member: Member) => {
    setEditingMember(member);
  }, []);
  const handleAddNewMember = useCallback(() => {
    setEditingMember({});
  }, []);
  const handleCloseMemberModal = useCallback(() => setEditingMember(null), []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.filters}>
          <ButtonGroupPicker
            options={VIEW_MODE_OPTIONS}
            onChange={setViewMode}
            value={viewMode}
          />
          <input
            type="text"
            placeholder="Sök"
            className={styles.searchInput}
            value={filterString}
            onChange={e => setFilterString(e.currentTarget.value)}
          />
        </div>
        <div className={styles.membersControl}>
          <div>{aliveMembersCount} medlemmar</div>
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleAddNewMember}
          >
            Ny medlem
          </button>
        </div>
      </div>
      {viewMode === 'list' ? (
        <MemberList
          members={members}
          filterString={filterString}
          onClickMember={handleClickMember}
        />
      ) : (
        <FamilyTree
          members={members}
          filterString={filterString}
          onClickMember={handleClickMember}
        />
      )}
      {editingMember != null && (
        <MemberEditModal
          member={editingMember}
          isModerator={isModerator}
          onClose={handleCloseMemberModal}
        />
      )}
    </div>
  );
};
export default MembersAppRouteWrapper;
