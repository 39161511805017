import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMembers } from 'shared/state/shared.selectors';
import { Link, useNavigate, useParams } from 'react-router-dom';
import range from 'lodash/range';
import BookingList from './components/BookingList';
import { useGetBookingsByYearQuery } from './state/reporting.slice';

const YEARS = range(2019, new Date().getFullYear() + 1).reverse();
const ReportedBookingsRoute: React.FC = () => {
  const navigate = useNavigate();
  const members = useSelector(getMembers);
  const params = useParams<{ year: string }>();

  let year = Number(params.year);
  if (!YEARS.includes(year)) {
    year = new Date().getFullYear();
  }
  const { data: allBookings = [], isFetching } =
    useGetBookingsByYearQuery(year);

  const bookings = useMemo(() => {
    return allBookings.filter(b => b.report != null);
  }, [allBookings]);

  // Get via useSelector to use a nicer props (year as number instead of match.params.year as string)
  const isEmpty = bookings.length < 1 && !isFetching;

  return (
    <div>
      {YEARS.length > 1 && (
        <div className="yearSelect">
          {YEARS.map(y => (
            <Link
              key={y}
              to={`/loggbok/rapporter/${y}`}
              className={y === year ? 'active' : undefined}
            >
              {y}
            </Link>
          ))}
        </div>
      )}
      <h1>Rapporter för året {year}</h1>
      <div className="main">
        {isEmpty ? (
          'Inga bokningar är rapporterade för det valda året'
        ) : (
          <BookingList
            bookings={bookings}
            members={members}
            onSelect={id => navigate(`/loggbok/${id == null ? '' : id}`)}
          />
        )}
      </div>
    </div>
  );
};

export default ReportedBookingsRoute;
