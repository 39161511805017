import * as React from 'react';
import {
  createBrowserRouter,
  NavLink,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import UnreportedBookingsRoute from './UnreportedBookingsRoute';
import SingleBookingRoute from './SingleBookingRoute';
import ReportedBookingsRoute from './ReportedBookingsRoute';
import ReportStatisticsRoute from './ReportStatisticsRoute';

const Layout: React.FC = () => {
  return (
    <div className="reporting">
      <div className="units-row">
        <div className="unit-25">
          <div className="section">
            <ul className="pages">
              <li>
                <NavLink to="/loggbok/" end>
                  Att rapportera
                </NavLink>
              </li>
              <li>
                <NavLink to="/loggbok/rapporter">Rapporter</NavLink>
              </li>
              {/* <li><NavLink to='/loggbok/statistik'>Statistik</NavLink></li> */}
            </ul>
          </div>
        </div>
        <div className="unit-75">
          <div className="section">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/loggbok',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <UnreportedBookingsRoute />,
      },
      {
        path: ':bookingId',
        element: <SingleBookingRoute />,
      },
      {
        path: 'rapporter/:year?',
        element: <ReportedBookingsRoute />,
      },
      {
        path: 'statistik/:year?',
        element: <ReportStatisticsRoute />,
      },
    ],
  },
]);

export default function ReportingApp() {
  return <RouterProvider router={router} />;
}
