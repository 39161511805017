import { uniqBy } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  type State,
  initialState,
  receiveMembers,
  receiveSingleMember,
} from './shared.types';

const sharedReducer = reducerWithInitialState<State>(initialState)
  .case(
    receiveMembers,
    (state, payload): State => ({
      ...state,
      members: payload,
    }),
  )
  .case(
    receiveSingleMember,
    (state, payload): State => ({
      ...state,
      members: uniqBy([payload, ...state.members], 'id'),
    }),
  );

export default sharedReducer;
