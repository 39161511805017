import { DateTime } from 'luxon';
import sum from 'lodash/sum';

import type { Member, ReportParticipant } from '../../../types/api';

interface ReportCalculationResult {
  totalPoints: number;
  totalCost: number;
  participants: Array<ReportParticipantCalculationResult>;
}

export function calculateCosts(
  participants: Array<ReportParticipant>,
  accompanyingMemberId: number | null,
  numFreeNights: number,
): ReportCalculationResult {
  const payingMemberPresent =
    accompanyingMemberId != null ||
    participants.some(p => !!p.member_is_paying);
  const participantResults = participants.map(p =>
    calculateParticipantCosts(p, payingMemberPresent, numFreeNights),
  );
  const totalCost = sum(participantResults.map(x => x.cost));
  const totalPoints = sum(participantResults.map(x => x.points));
  return {
    totalPoints,
    totalCost,
    participants: participantResults,
  };
}

interface ReportParticipantCalculationResult {
  participant: ReportParticipant;
  cost: number;
  costComponents: Array<string>;
  points: number;
  pointComponents: Array<string>;
}

export function calculateParticipantCosts(
  participant: ReportParticipant,
  payingMemberPresent: boolean,
  numFreeNights: number,
): ReportParticipantCalculationResult {
  const { member_id, num_nights, num_boat_rides, pay_instead_of_points } =
    participant;

  const numNightsToPayFor = Math.max(0, num_nights - numFreeNights);
  const freeNightsParanthesis =
    numFreeNights > 0 ? ` (${numFreeNights} gratis nätter)` : '';

  const numBoatRightsToPayFor = Math.max(
    0,
    num_boat_rides - (numFreeNights > 0 ? num_boat_rides : 0),
  );
  const freeBoatRidesParanthesis =
    numFreeNights > 0 ? ` (${num_boat_rides} gratis båtskjutsar)` : '';

  const age = participant.age != null ? participant.age : 18;
  const isMember = member_id != null;
  const isPayingMember = isMember && participant.member_is_paying;

  let cost = 0;
  const costComponents = [];
  let points = 0;
  const pointComponents = [];

  let price = 25;
  let pointPrice = 1;
  if (age < 18) {
    // Everything is free below 18
    price = 0;
  }
  if (!payingMemberPresent) {
    // If no member is present to take the points, it costs 100kr for members and 200kr for guests
    price = isMember ? 100 : 200;
    pointPrice = 0;
  }
  if (isPayingMember) {
    // Paying members: free
    price = 0;
  }
  if (age < 12) {
    // Under 12: no points, so no penalty
    pointPrice = 0;
    price = 0;
  } else if (pay_instead_of_points) {
    // Over 12, and paying instead of points
    price = 200;
    pointPrice = 0;
  }

  cost += price * numNightsToPayFor;
  costComponents.push(
    `${numNightsToPayFor} nätter ✕ ${price} kr${
      price > 0 ? freeNightsParanthesis : ''
    }`,
  );
  points += pointPrice * numNightsToPayFor;
  pointComponents.push(
    `${numNightsToPayFor} nätter ✕ ${pointPrice} poäng${
      pointPrice > 0 ? freeNightsParanthesis : ''
    }`,
  );

  if (num_boat_rides > 0 && !isPayingMember) {
    let boatPrice = isPayingMember ? 0 : 25;
    if (!payingMemberPresent && !isPayingMember) {
      boatPrice = isMember ? 100 : 200;
    }
    if (age < 18) {
      // Boat is free below 18
      boatPrice = 0;
    }
    cost += boatPrice * numBoatRightsToPayFor;
    costComponents.push(
      `${numBoatRightsToPayFor} båtskjutsar ✕ ${boatPrice}kr${
        boatPrice > 0 ? freeBoatRidesParanthesis : ''
      }`,
    );
  }

  return {
    participant,
    cost,
    costComponents,
    points,
    pointComponents,
  };
}

const TODAY = DateTime.local().toISODate();
export function calculateMemberAge(
  member: Member,
  referenceDate: string = TODAY,
): number | null {
  let birthDate = DateTime.fromFormat(member.date_of_birth, 'yyyy-MM-dd');
  if (!birthDate.isValid) {
    const yearMatch = member.date_of_birth.match(/^(\d{4})/);
    if (yearMatch != null) {
      birthDate = DateTime.fromFormat(yearMatch[1], 'yyyy');
    }
  }
  if (birthDate.isValid) {
    return Math.floor(
      DateTime.fromISO(referenceDate).diff(birthDate, 'years').years,
    );
  }
  return null;
}
