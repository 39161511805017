import type { Booking, BookingReport, CalendarEvent } from 'types/api';
import { parseBookingDate } from 'shared/utils/dateHelpers';

export interface EnrichedBooking extends Omit<Booking, 'report'> {
  yearFrom: number;
  yearTo: number;
  report?: BookingReport | null | undefined;
}
interface EnrichBookingDependencies {
  events: Array<CalendarEvent>;
}
export function enrichBooking(
  booking: Booking,
  dependencies: EnrichBookingDependencies,
): EnrichedBooking {
  const yearFrom = parseBookingDate(booking.date_from).getFullYear();
  const yearTo = parseBookingDate(booking.date_to).getFullYear();
  let events: Array<CalendarEvent> | null = null;
  {
    const eventsFrom = dependencies.events;
    const eventsTo = dependencies.events;
    if (eventsFrom != null && eventsTo != null) {
      events = eventsFrom.concat(yearFrom !== yearTo ? eventsTo : []);
    }
  }
  return {
    ...booking,
    yearTo,
    yearFrom,
  };
}
