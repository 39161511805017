import React, { useState, useCallback } from 'react';
import Modal from 'shared/components/Modal';

const useConfirmationModal = ({
  onConfirm,
  confirmButtonLabel,
}: {
  onConfirm: () => void;
  confirmButtonLabel: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const trigger = useCallback(() => setIsOpen(true), []);
  const dismiss = useCallback(() => setIsOpen(false), []);

  const handleConfirm = useCallback(() => {
    onConfirm();
    dismiss();
  }, [onConfirm, dismiss]);

  const render = useCallback(() => {
    if (!isOpen) {
      return null;
    }

    return (
      <Modal onClose={dismiss}>
        Är du helt säker?
        <div style={{ marginTop: '1rem' }}>
          <button type="button" className="btn btn-red" onClick={handleConfirm}>
            {confirmButtonLabel}
          </button>
          <button type="button" className="btn" onClick={dismiss}>
            Avbryt
          </button>
        </div>
      </Modal>
    );
  }, [isOpen, dismiss, handleConfirm, confirmButtonLabel]);

  return {
    trigger,
    dismiss,
    render,
  };
};

export default useConfirmationModal;
