import React, { useCallback, useState, useEffect } from 'react';

interface OwnProps {
  value: number | null | undefined;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
}

type InputProps = React.HTMLProps<HTMLInputElement>;
type Props = Omit<InputProps, 'type' | 'onChange' | 'value'> & OwnProps;

const NumberInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    value: numericValue,
    onChange: numericOnChange,
    onBlur,
    minValue,
    maxValue,
    ...rest
  } = props;

  // Keep a nullable undefined value in state - this is the source of truth
  const [stateValue, setValueInState] = useState<string | undefined>(
    props.value == null ? undefined : String(props.value),
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // If the input is emptied, set value to undefined and propagate 0
      const value = e.target.value;
      setValueInState(value.length === 0 ? undefined : value);
      const newNumericValue = +value;
      numericOnChange(Math.max(newNumericValue, minValue ?? 0));
    },
    [numericOnChange, minValue],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      // If the state value is undefined, reset on blur
      if (numericValue != null) {
        setValueInState(String(numericValue));
      }
      // Propagate event
      if (onBlur) {
        onBlur(e);
      }
    },
    [numericValue, onBlur],
  );

  // Accept changes to our state from props to keep in sync
  const receiveProps = useCallback(() => {
    const propValueIsZeroButFieldIsEmpty =
      numericValue === (minValue ?? 0) && stateValue == null;
    if (propValueIsZeroButFieldIsEmpty) {
      // Let the user have an empty input field
      return;
    }
    if (String(numericValue) !== stateValue) {
      // Accept change
      setValueInState(numericValue == null ? undefined : String(numericValue));
    }
  }, [numericValue, stateValue, minValue]);
  // Fire function when props.value changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: Lint is wrong
  useEffect(receiveProps, [receiveProps]);

  return (
    <input
      {...rest}
      type="number"
      ref={ref}
      onChange={onChange}
      value={stateValue == null ? '' : stateValue}
      onBlur={handleBlur}
      max={maxValue}
    />
  );
});
export default NumberInput;
