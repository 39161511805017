import * as React from 'react';

import { calculateCosts } from '../logic/calculateCosts';

import type { Member, ReportParticipant } from '../../../types/api';

interface Props {
  members: Array<Member>;
  participants: Array<ReportParticipant>;
  accompanyingMemberId: number | null;
  comment: string;
  numFreeNights: number;
}

export default class Report extends React.Component<Props> {
  getName(participant: ReportParticipant) {
    const { members } = this.props;
    const { guest_name, member_id } = participant;
    let member = null;
    if (member_id != null) {
      member = members.find(m => m.id === member_id);
    }
    if (member != null) {
      return member.name;
    }
    if (guest_name != null) {
      return guest_name;
    }

    return 'Okänd';
  }

  renderParticipantTitle(participant: ReportParticipant) {
    const { pay_instead_of_points, member_is_paying, member_id } = participant;

    const title = this.getName(participant);
    const ageYears = participant.age != null ? participant.age : 18;
    let type: string | null = 'Gäst';
    if (member_id != null) {
      type = 'Icke-betalande medlem';
      if (member_is_paying) {
        type = 'Betalande medlem';
      }
    }

    let age = 'Vuxen';
    if (ageYears < 12) {
      age = 'Barn';
      type = null;
    } else if (ageYears < 18) {
      age = 'Ungdom';
    }

    const subtitle = [
      type,
      age,
      pay_instead_of_points && ageYears >= 12 ? '200kr per natt' : null,
    ]
      .filter(x => x != null)
      .join(', ');

    return (
      <div className="participantTitle">
        {title}
        <small>{subtitle}</small>
      </div>
    );
  }

  render() {
    const {
      participants,
      comment,
      accompanyingMemberId,
      members,
      numFreeNights,
    } = this.props;
    const result = calculateCosts(
      participants,
      accompanyingMemberId,
      numFreeNights,
    );

    let commentElement = null;
    if (comment != null && comment.length > 0) {
      commentElement = (
        <div className="comment">
          <strong>Kommentar: </strong>
          {comment}
        </div>
      );
    }
    let accompanyingMemberElement = null;
    if (
      !participants.some(p => !!p.member_is_paying) &&
      accompanyingMemberId != null
    ) {
      const accompanyingMember = members.find(
        m => m.id === accompanyingMemberId,
      );
      const accompanyingMemberName =
        accompanyingMember != null
          ? accompanyingMember.name
          : `Okänd (ID: #${accompanyingMemberId})`;
      accompanyingMemberElement = (
        <div style={{ marginBottom: 10 }}>
          I sällskap av betalande medlem:{' '}
          <strong>{accompanyingMemberName}</strong>
        </div>
      );
    }
    return (
      <div className="report">
        {result.participants.map(
          (
            { participant, costComponents, cost, pointComponents, points },
            i,
          ) => (
            <div key={i}>
              {this.renderParticipantTitle(participant)}
              <table>
                <tbody>
                  <tr>
                    <th>Kostnad</th>
                    <td>{costComponents.join(' + ')}</td>
                    <td>
                      = <strong>{cost}</strong> kr
                    </td>
                  </tr>
                  <tr>
                    <th>Poäng</th>
                    <td>{pointComponents.join(' + ')}</td>
                    <td>
                      = <strong>{points}</strong> poäng
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ),
        )}
        {accompanyingMemberElement}
        <div className="total">
          Totalt: <strong>{result.totalCost}</strong> kronor
          {', '}
          <strong>{result.totalPoints}</strong> poäng
        </div>
        {commentElement}
      </div>
    );
  }
}
