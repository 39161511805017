import * as React from 'react';

import { calculateCosts } from '../logic/calculateCosts';
import * as dateHelpers from '../../../shared/utils/dateHelpers';

import type { BookingReport, Booking, Member } from '../../../types/api';

interface Props {
  booking: Booking;
  report: BookingReport;
  members: Array<Member>;
}

export default class PaymentSummary extends React.Component<Props> {
  render() {
    const { booking, report } = this.props;
    const { report_participants: participants, accompanying_member_id } =
      report;
    const { num_free_nights } = booking;
    const result = calculateCosts(
      participants,
      accompanying_member_id,
      num_free_nights,
    );

    if (result.totalCost <= 0) {
      return null;
    }

    const formatDate = (d: string) =>
      dateHelpers.formatDateShort(dateHelpers.parseBookingDate(d));
    const when = `${formatDate(booking.date_from)} - ${formatDate(
      booking.date_to,
    )}`;
    const bookingId = booking.id != null ? booking.id : '?';
    const numParticipants = participants.length;
    const amount = result.totalCost;
    const message =
      `Rapport #${bookingId}, ${numParticipants} resenärer, ${when}`.slice(
        0,
        50,
      );
    const qrUrl = `/api/swish_qr?amount=${amount}&message=${encodeURIComponent(
      message,
    )}`;

    // const linkData = {
    //   version: 1,
    //   payee: {
    //     value: 1236090898,
    //     editable: false,
    //   },
    //   amount: {
    //     value: amount,
    //     editable: true,
    //   },
    //   message: {
    //     value: message,
    //     editable: true,
    //   },
    // };
    // const link = `swish://payment?data=${JSON.stringify(linkData)}`;

    return (
      <div style={{ textAlign: 'center', paddingTop: 10 }}>
        {/* <a href={link}> */}
        <img
          src={qrUrl}
          style={{ height: 150, width: 150, margin: '0px auto 10px auto' }}
          alt={`Swishkod för betalning: ${amount} kr till 123-60 90 898`}
        />
        {/* </a> */}
        <p style={{ margin: '0 0 5px 0' }}>
          <strong>{result.totalCost}</strong> kr till{' '}
          <strong>123 60 90 898</strong>
        </p>
        <p>
          <a href="/betalningsinformation">Betalningsinformation</a>
        </p>
      </div>
    );
  }
}
