import * as React from 'react';
import sortBy from 'lodash/sortBy';

import BookingForm from './BookingForm';
import * as helpers from './helpers';
import * as dateHelpers from '../../shared/utils/dateHelpers';

import type { Booking, BookingDraft, CalendarEvent } from '../../types/api';
import Tooltip from 'shared/components/Tooltip';
import api from 'shared/api';
import { getMembers } from 'shared/state/shared.selectors';
import CalendarBookingTooltip, {
  type PopoutState,
} from './CalendarBookingPopout';
import { useCallback, useRef, useState } from 'react';
import { useMountEffect } from 'shared/hooks/useMountEffect';
import { useEffectOnValueChange } from 'shared/hooks/useEffectOnValueChange';
import { useOnClickOutside } from 'shared/hooks/useOnClickOutside';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

interface CalendarSelection {
  start: Date;
  end: Date;
}

interface CalendarState {
  booking: BookingDraft;
  saving: boolean;
  popout: PopoutState | null;
  selection: CalendarSelection | null;
  bookingsByYear: Record<number, Array<Booking> | undefined>;
  eventsByYear: Record<number, Array<CalendarEvent> | undefined>;
  month: number;
  year: number;
}

const emptyBooking: BookingDraft = {
  member_id: null,
  who: '',
  date_from: '',
  date_to: '',
  booking_type: 'speculation',
  description: '',
  num_people: 1,
  num_non_members: 0,
  social: true,
};

interface Props {
  data: {
    bookings: Array<Booking>;
    events: Array<CalendarEvent>;
  };
}

const Calendar = (props: Props) => {
  const { initialMonth, initialYear } = React.useMemo(() => {
    const today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();

    // Initialize from given month
    try {
      const query: Record<string, string> = {};
      const pairs = window.location.search.substr(1).split('&');
      for (const pair of pairs) {
        const [name, value] = pair.split('=');
        if (name && value) {
          query[decodeURIComponent(name)] = decodeURIComponent(value);
        }
      }
      const view = query.vy;
      if (view != null) {
        const [yearString, monthString] = view.split('-');
        if (yearString && monthString) {
          year = Number.parseInt(yearString, 10);
          month = Number.parseInt(monthString, 10) - 1;
        }
      }
    } catch (e) {
      // Ignore
    }

    return { initialMonth: month, initialYear: year };
  }, []);

  const [view, setViewValidated] = useState({
    month: initialMonth,
    year: initialYear,
  });
  const setView = useCallback((view: { month: number; year: number }) => {
    setViewValidated(validateView(view));
  }, []);

  useEffectOnValueChange(
    view,
    () => {
      const { year, month } = view;

      fetchBookings(year);
      fetchEvents(year);
      if (window.history != null && window.history.replaceState != null) {
        const newSearch = `?vy=${year}-${dateHelpers.pad2(month + 1)}`;
        window.history.replaceState(null, '', newSearch);
      }
    },
    true,
  );
  const members = useSelector(getMembers);
  const [bookingDraft, setBookingDraft] = useState(emptyBooking);
  const [formDatesHighlightCount, setFormDatesHighlightCount] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [popout, setPopout] = useState<PopoutState | null>(null);
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [bookingsByYear, setBookingsByYear] = useState({
    [view.year]: props.data.bookings,
  });
  const [eventsByYear, setEventsByYear] = useState({
    [view.year]: props.data.events,
  });
  const [calendarRef, setCalendarRef] = useState<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);
  const touchRef = useRef<React.Touch | null>(null);
  const [selection, setSelection] = useState<CalendarSelection | null>(null);

  useMountEffect(() => {
    const listener = (e: Event) => {
      const el = e.target;
      if (!(el instanceof HTMLElement)) {
        return;
      }

      if (
        el.closest('.info-box') != null ||
        el.className?.includes('booking') ||
        el.closest('.booking') != null
      ) {
        return;
      }

      setPopout(null);

      // Stop propagation
      e.stopPropagation();
    };

    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  });

  const reset = () => {
    setBookingDraft(emptyBooking);
    setIsSaving(false);
    setSelection(null);
    setPopout(null);
    setBookingsByYear(bookingsByYear => ({
      // Keep current year, we're downloading it anyway
      [view.year]: bookingsByYear[view.year],
    }));
    setEventsByYear(bookingsByYear => ({
      // See above
      [view.year]: bookingsByYear[view.year],
    }));
    fetchBookings(view.year);
    fetchEvents(view.year);

    makeSureVisible(calendarRef);
  };

  const resetYearAndMonth = () => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    setView({ month, year });
    setPopout(null);
  };

  const fetchBookings = async (year: number): Promise<void> => {
    const bookings = await api.bookings.fetchByYear(year);
    setBookingsByYear(bookingsByYear => ({
      ...bookingsByYear,
      [year]: bookings,
    }));
  };

  const fetchEvents = async (year: number): Promise<void> => {
    const events = await api.events.fetchByYear(year);
    setEventsByYear(eventsByYear => ({
      ...eventsByYear,
      [year]: sortBy(events, (e: CalendarEvent) =>
        dateHelpers.parseBookingDate(e.date_from),
      ),
    }));
  };

  const handleGoToPreviousMonth = (e?: React.SyntheticEvent) => {
    if (e != null) {
      e.preventDefault();
    }
    let { year, month } = view;

    if (month === 0) {
      month = 11;
      year -= 1;
    } else {
      month -= 1;
    }

    setView({ year, month });
    setPopout(null);
    makeSureVisible(calendarRef, true);
  };

  const handleGoToNextMonth = (e?: React.SyntheticEvent) => {
    if (e != null) {
      e.preventDefault();
    }
    let { year, month } = view;

    if (month === 11) {
      month = 0;
      year += 1;
    } else {
      month += 1;
    }

    setView({ year, month });
    setPopout(null);
    makeSureVisible(calendarRef, true);
  };

  const handleWheel = (e: React.WheelEvent<HTMLElement>) => {
    if (!e.shiftKey && !e.altKey) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (e.deltaY < 0 || e.deltaX < 0) {
      handleGoToPreviousMonth();
    } else {
      handleGoToNextMonth();
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    touchRef.current = e.touches[0];
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    const body = document.body;
    if (touchRef.current == null || body == null) {
      return;
    }

    const newTouch: React.Touch = e.touches[0];

    const deltaX = touchRef.current.clientX - newTouch.clientX;
    const deltaY = touchRef.current.clientY - newTouch.clientY;

    const { abs } = Math;
    if (abs(deltaY) > 30 && abs(deltaY) > abs(deltaX)) {
      // Too much vertical movement
      touchRef.current = null;
      return;
    }

    const factor = deltaX / body.offsetWidth;
    const threshold = 0.3;
    if (factor > threshold) {
      handleGoToNextMonth();
    } else if (factor < -threshold) {
      handleGoToPreviousMonth();
    } else {
      return;
    }

    touchRef.current = null;
  };

  const handleAdjustSelection = useCallback(
    (start: Date, end: Date) => {
      const newBooking: BookingDraft = {
        ...bookingDraft,
        date_from: dateHelpers.formatDate(start),
        date_to: dateHelpers.formatDate(end),
      };
      setSelection({ start, end });
      setBookingDraft(newBooking);
      setFormDatesHighlightCount(i => i + 1);
    },
    [bookingDraft],
  );
  const [currentDragSelection, setCurrentDragSelection] = useState<{
    pivot: Date;
    end: Date;
  } | null>(null);
  const createDayMouseDownHandler = (date: Date) => (e: React.MouseEvent) => {
    setCurrentDragSelection({ pivot: date, end: date });
    if (
      !selection ||
      !(
        selection.start != null &&
        selection.end === selection.start &&
        date > selection.end
      )
    ) {
      handleAdjustSelection(date, date);
    } else {
      handleAdjustSelection(selection.start, date);
    }
  };
  const createDayMouseEnterHandler = (date: Date) => (e: React.MouseEvent) => {
    setHoveredDate(date);
    if (!currentDragSelection) {
      return;
    }
    const { pivot } = currentDragSelection;
    setCurrentDragSelection({ pivot, end: date });
    const [start, end] = [pivot, date].sort(
      (a, b) => a.getTime() - b.getTime(),
    );
    handleAdjustSelection(start, end);
  };
  useMountEffect(() => {
    const listener = (e: Event) => {
      setCurrentDragSelection(null);
      // Only for mouseleave
      if (e.type === 'mouseleave') {
        setHoveredDate(null);
      }
    };
    window.addEventListener('mouseup', listener);
    document.body.addEventListener('mouseleave', listener);
    return () => {
      window.removeEventListener('mouseup', listener);
      document.body.removeEventListener('mouseleave', listener);
    };
  });
  const createDayMouseLeaveHandler = (date: Date) => (e: React.MouseEvent) => {
    setHoveredDate(null);
  };

  const handleSave = () => {
    const finalBooking = { ...bookingDraft };
    // Sanitize data
    if (finalBooking.booking_type === 'exclusive') {
      finalBooking.social = false;
    }

    setIsSaving(true);
    api.bookings.saveBooking(finalBooking).then(
      () => {
        reset();
        // TODO: feedback - toast?
        makeSureVisible(calendarRef);
      },
      e => {
        window.alert(`Ett fel uppstod: "${e.toString()}". Kontakta Orvar`);
        setIsSaving(false);
      },
    );
  };

  const handleEditPopoutBooking = () => {
    if (popout == null) {
      return;
    }
    setBookingDraft(popout.booking);
    setPopout(null);
    setSelection({
      start: dateHelpers.parseBookingDate(popout.booking.date_from),
      end: dateHelpers.parseBookingDate(popout.booking.date_to),
    });
    setFormDatesHighlightCount(i => i + 1);
    makeSureVisible(formRef.current);
  };

  const handleDeletePopoutBooking = () => {
    if (popout == null) {
      return;
    }

    const { booking } = popout;
    const id = booking.id;
    if (id == null) {
      return;
    }

    const range = dateHelpers.formatRange(
      dateHelpers.parseBookingDate(booking.date_from),
      dateHelpers.parseBookingDate(booking.date_to),
    );

    if (
      !window.confirm(`Du tar bort bokningen ${range} av ${booking.who}`) ||
      !window.confirm('Är du helt säker på att du vill ta bort bokningen?')
    ) {
      return;
    }

    setPopout(null);
    api.bookings.deleteBooking(id).then(reset);
  };

  const handleClickBooking = (
    e: React.MouseEvent<HTMLDivElement>,
    booking: Booking,
  ) => {
    if (popout?.booking === booking) {
      return setPopout(null);
    }
    const bookingElement = e.currentTarget;
    const calendarElement = calendarRef;
    if (calendarElement == null) {
      return;
    }

    const bookingRect = bookingElement.getBoundingClientRect();
    const calendarRect = calendarElement.getBoundingClientRect();

    const bottom = calendarRect.bottom - bookingRect.bottom;

    let left = e.clientX - calendarRect.left;

    // Make sure popout (which is 320px) never goes outside calendar
    if (left > calendarRect.width - 160) {
      left = calendarRect.width - 160;
    } else if (left < 160) {
      left = 160;
    }

    setPopout({
      booking,
      left,
      bottom,
    });
  };

  const handleClickDates = () => {
    // TODO
    // calendar.getElement('.grid-container').highlight('#B3DAE6');
    makeSureVisible(calendarRef);
  };

  const classNameFor = (day: Date): string => {
    const { year, month } = view;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const classNames = [];

    if (day.getMonth() !== month) {
      classNames.push('not-current-month');
    }

    if (day.toDateString() === today.toDateString()) {
      classNames.push('today');
    }

    if (selection && selection.start <= day && day <= selection.end) {
      classNames.push('selected');
    }

    if (hoveredDate?.toDateString() === day.toDateString()) {
      classNames.push('hover');
    }

    if (
      selection != null &&
      hoveredDate != null &&
      selection.start === selection.end &&
      hoveredDate > selection.start &&
      isBetweenDates(day, selection.start, hoveredDate)
    ) {
      classNames.push('selection-pending');
    }

    const event = (eventsByYear[year] || []).find((e: CalendarEvent) => {
      const eventStart = dateHelpers.parseBookingDate(e.date_from);
      const eventEnd = dateHelpers.parseBookingDate(e.date_to);
      return eventStart <= day && day <= eventEnd;
    });

    if (event != null) {
      classNames.push('has-event', `has-event--${event.event_type}`);
    }

    return classNames.join(' ');
  };

  const renderForm = () => {
    return (
      <BookingForm
        innerRef={formRef}
        onClickDateInput={handleClickDates}
        booking={bookingDraft}
        members={members}
        onChange={setBookingDraft}
        onSave={handleSave}
        onCancel={reset}
        isSaving={isSaving}
        highlightDatesCount={formDatesHighlightCount}
      />
    );
  };

  const renderPopout = () => {
    if (!popout) {
      return null;
    }
    return (
      <CalendarBookingTooltip
        state={popout}
        members={members}
        onEdit={handleEditPopoutBooking}
        onDelete={handleDeletePopoutBooking}
      />
    );
  };

  const renderBooking = (booking: Booking) => {
    let bookingType = booking.booking_type;
    if (bookingType === 'speculation' && booking.report) {
      bookingType = 'planned';
    }
    const classNames = ['booking', `type-${bookingType}`];

    if (booking.report) {
      classNames.push('reported');
    }

    if (bookingDraft.id != null && booking.id === bookingDraft.id) {
      classNames.push('editing');
    }

    let deletedIcon = null;
    if (booking.deleted) {
      classNames.push('deleted');
      deletedIcon = <span className="prefix">✖</span>;
    }
    let socialIcon = null;
    if (booking.social) {
      socialIcon = (
        <Tooltip tagName="span" content="Vill gärna ha sällskap">
          <span className="prefix">♥</span>
        </Tooltip>
      );
    }

    const deleted = booking.deleted;
    const hasReport = booking.report != null;
    const timeSinceEnd = dateHelpers.dateDiffDays(
      dateHelpers.parseBookingDate(booking.date_to),
      new Date(),
    );
    const isReportRelevant = booking.date_to_year >= 2019;
    let reportIcon = null;
    if (!deleted && !hasReport && timeSinceEnd > 7 && isReportRelevant) {
      reportIcon = (
        <span className="prefix unreported">
          <Tooltip tagName="span" content="Bokningen saknar rapport">
            ❗
          </Tooltip>
        </span>
      );
      socialIcon = null;
    } else if (hasReport && !deleted) {
      reportIcon = (
        <span className="prefix reported">
          <Tooltip tagName="span" content="Bokningen har rapport">
            ✓
          </Tooltip>
        </span>
      );
      socialIcon = null;
    }

    const bookingMarkup = (
      <div
        className={classNames.join(' ')}
        onClick={e => handleClickBooking(e, booking)}
      >
        <div className="booking-text-wrapper">
          {reportIcon}
          {deletedIcon}
          {socialIcon}
          <span className="name">
            {booking.who} ({booking.num_people})
          </span>
          <span className="description">{booking.description}</span>
        </div>
      </div>
    );

    return <div className="booking-wrapper">{bookingMarkup}</div>;
  };

  const renderEvents = (row: Array<Date>) => {
    const { year } = view;
    const events = eventsByYear[year] || [];

    interface Cell {
      width: number;
      classNames: Array<string>;
      event?: CalendarEvent;
    }
    const cells: Array<Cell> = [];
    let numEvents = 0;
    for (let col = 0; col < 7; col++) {
      const day = row[col];
      const event = events.find((e: CalendarEvent) => {
        const eventStart = dateHelpers.parseBookingDate(e.date_from);
        const eventEnd = dateHelpers.parseBookingDate(e.date_to);
        return eventStart <= day && day <= eventEnd;
      });
      if (event != null) {
        const startOfWeek = row[0];
        const endOfWeek = row[row.length - 1];
        const dateFrom = dateHelpers.parseBookingDate(event.date_from);
        const dateTo = dateHelpers.parseBookingDate(event.date_to);
        let startIndex = 0;
        if (dateFrom > startOfWeek) {
          startIndex = dateHelpers.dateDiffDays(startOfWeek, dateFrom);
        }
        let endIndex = 6;
        if (dateTo < endOfWeek) {
          endIndex = dateHelpers.dateDiffDays(startOfWeek, dateTo);
        }
        const width = Math.min(7 - col, endIndex - startIndex) + 1;

        const suppress = startIndex !== col;

        const cell: Cell = {
          event: suppress ? undefined : event,
          width: width,
          classNames: [`event--${event.event_type}`],
        };

        cells.push(cell);
        numEvents += 1;
      } else {
        const cell: Cell = {
          classNames: [],
          width: 1,
        };

        cells.push(cell);
      }
    }

    if (numEvents < 1) {
      return null;
    }

    return (
      <div className="row">
        {cells.map((cell, i) => (
          <div
            key={i}
            className={['cell', 'event-cell', ...cell.classNames].join(' ')}
            title={cell.event?.title}
            onMouseDown={createDayMouseDownHandler(row[i])}
            onMouseEnter={createDayMouseEnterHandler(row[i])}
            onMouseLeave={createDayMouseLeaveHandler(row[i])}
          >
            {cell.event && renderEvent(cell.event, cell.width)}
          </div>
        ))}
      </div>
    );
  };

  const renderEvent = (event: CalendarEvent, width: number) => {
    return (
      <div className="event-wrapper" style={{ width: `${width * 100}%` }}>
        {event.title}
      </div>
    );
  };

  const renderBookings = (row: Array<Date>): Array<React.ReactNode> => {
    const { year } = view;
    const bookings = bookingsByYear[year] ?? [];

    const scheduleRows = helpers.makeScheduleForWeek(row[0], bookings);

    return scheduleRows.map((rowMap, rowIndex) => {
      interface Cell {
        width: number;
        classNames: Array<string>;
        booking?: Booking;
        onMouseDown?: (e: React.MouseEvent) => void;
        onMouseEnter?: (e: React.MouseEvent) => void;
        onMouseLeave?: (e: React.MouseEvent) => void;
      }
      const cells: Array<Cell> = [];
      let col = 0;
      while (col < 7 * 2) {
        if (rowMap[col] != null) {
          const { booking, startIndex, endIndex } = rowMap[col];
          const width = endIndex - startIndex + 1;

          const cell: Cell = {
            booking,
            width,
            classNames: [],
          };

          if (
            startIndex === 0 &&
            row[0] > dateHelpers.parseBookingDate(booking.date_from)
          ) {
            cell.classNames.push('hp');
          }
          if (
            endIndex === 7 * 2 - 1 &&
            row[6] < dateHelpers.parseBookingDate(booking.date_to)
          ) {
            cell.classNames.push('hf');
          }

          cells.push(cell);
          col += width;
        } else {
          const cell: Cell = {
            classNames: [],
            width: 1,
            onMouseDown: createDayMouseDownHandler(row[Math.floor(col / 2)]),
            onMouseEnter: createDayMouseEnterHandler(row[Math.floor(col / 2)]),
            onMouseLeave: createDayMouseLeaveHandler(row[Math.floor(col / 2)]),
          };

          cells.push(cell);
          col += 1;
        }
      }

      return (
        <div className="row" key={rowIndex}>
          {cells.map((cell, i) => (
            <div
              key={i}
              style={{ flex: cell.width }}
              className={['cell', 'booking-cell', ...cell.classNames].join(' ')}
              onMouseDown={cell.onMouseDown}
              onMouseEnter={cell.onMouseEnter}
              onMouseLeave={cell.onMouseLeave}
            >
              {cell.booking && renderBooking(cell.booking)}
            </div>
          ))}
        </div>
      );
    });
  };

  const renderCalendar = () => {
    const { year, month } = view;

    const calendar = helpers.makeCalendarForMonth(year, month);

    const rows = calendar.map((row, i) => (
      <div key={i} className="month-row">
        <div className="backgroundCells">
          {row.map(day => (
            <div
              key={day.toString()}
              className={classNames('cell', classNameFor(day))}
              onMouseDown={createDayMouseDownHandler(day)}
              onMouseEnter={createDayMouseEnterHandler(day)}
              onMouseLeave={createDayMouseLeaveHandler(day)}
            />
          ))}
        </div>
        <div className="mainCells">
          <div className="row">
            {row.map(day => (
              <div
                key={day.toString()}
                className={['cell', 'label-cell', classNameFor(day)].join(' ')}
                onMouseDown={createDayMouseDownHandler(day)}
                onMouseEnter={createDayMouseEnterHandler(day)}
                onMouseLeave={createDayMouseLeaveHandler(day)}
              >
                {day.getDate()}
              </div>
            ))}
          </div>
          {renderEvents(row)}
          {renderBookings(row)}
        </div>
        <div className="weekNo">{dateHelpers.getWeekNumber(row[0])}</div>
      </div>
    ));

    return (
      <div
        className="grid-container"
        onWheel={handleWheel}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {rows}
      </div>
    );
  };

  const bookings = bookingsByYear[view.year];
  const today = new Date();

  let headerText = 'Laddar...';
  if (bookings != null) {
    headerText = dateHelpers.monthName(view.month);
    if (view.year !== today.getFullYear()) {
      headerText = `${headerText} ${view.year}`;
    }
  }

  const header = (
    <div className="header">
      <div
        className="monthLink"
        onClick={handleGoToPreviousMonth}
        onKeyPress={handleGoToPreviousMonth}
        role="button"
        tabIndex={0}
      >
        ←
      </div>
      <div
        className="month"
        onClick={resetYearAndMonth}
        onKeyPress={resetYearAndMonth}
        tabIndex={0}
        role="button"
      >
        {headerText}
      </div>
      <div
        className="monthLink"
        onClick={handleGoToNextMonth}
        onKeyPress={handleGoToNextMonth}
        role="button"
        tabIndex={0}
      >
        →
      </div>
    </div>
  );

  return (
    <div id="calendar" ref={setCalendarRef}>
      {header}
      <div className="wrap">
        <table className="daynames">
          <thead>
            <tr>
              <td>Mån</td>
              <td>Tis</td>
              <td>Ons</td>
              <td>Tors</td>
              <td>Fre</td>
              <td>Lör</td>
              <td>Sön</td>
            </tr>
          </thead>
        </table>
        {renderCalendar()}
        {header}
      </div>
      {renderPopout()}
      {renderForm()}
    </div>
  );
};

export default Calendar;

const validateView = (view: { month: number; year: number }) => {
  const { year, month } = view;
  const today = new Date();

  if (year <= 2010) {
    return { year: 2011, month: 0 };
  }
  if (year >= today.getFullYear() + 2) {
    return { year: today.getFullYear() + 1, month: 11 };
  }
  if (month < 0) {
    return { year, month: 0 };
  }
  if (month > 11) {
    return { year, month: 11 };
  }
  return view;
};

const isBetweenDates = (date: Date, start: Date, end: Date) => {
  const [a, b] = [start, end].sort((a, b) => a.getTime() - b.getTime());
  return a <= date && date <= b;
};

const makeSureVisible = (
  element: HTMLElement | null,
  ensureTopVisible?: boolean,
) => {
  const mobile = document.body.offsetWidth <= 1000;
  const topVisible =
    element != null && window.scrollY < element.getBoundingClientRect().top;
  const doScroll = ensureTopVisible ? !topVisible : mobile;
  if (doScroll && element != null) {
    element.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'nearest',
    });
  }
};
