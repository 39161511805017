import React, { type ComponentType } from 'react';
import ReactModal, { type Styles as ReactModalStyles } from 'react-modal';
import styles from './Modal.module.scss';

interface Props {
  onClose: () => void;
  children: React.ReactNode;
}

const style = {
  content: {},
  overlay: {},
} satisfies ReactModalStyles;

const ReactModalReact18Safe = ReactModal as ComponentType<ReactModal.Props>;

const Modal = (props: Props) => (
  <ReactModalReact18Safe
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
    isOpen
    onRequestClose={props.onClose}
    style={style}
    className={styles.modal}
    overlayClassName={styles.backdrop}
  >
    {props.children}
  </ReactModalReact18Safe>
);

export default Modal;
