import type { BookingDraft } from '../../types/api';

export interface ValidationResult {
  success: boolean;
  invalidFields: Array<string>;
}

export default function validateBooking(
  booking: BookingDraft,
): ValidationResult {
  const invalidFields = [];

  if (booking.member_id == null) {
    invalidFields.push('member_id');
  }

  if (booking.who.length === 0) {
    invalidFields.push('who');
  }

  if (booking.date_from.length === 0) {
    invalidFields.push('date_from');
  }
  if (booking.date_to.length === 0) {
    invalidFields.push('date_to');
  }

  if (booking.num_people < 1) {
    invalidFields.push('num_people');
  }
  if (booking.num_non_members < 0) {
    invalidFields.push('num_non_members');
  }
  if (booking.num_non_members > booking.num_people) {
    invalidFields.push('num_people');
    invalidFields.push('num_non_members');
  }

  return {
    success: invalidFields.length === 0,
    invalidFields,
  };
}
