import React from 'react';
import { pluralize } from 'shared/utils/text';
import type { Booking, Member } from 'types/api';
import * as dateHelpers from '../../shared/utils/dateHelpers';

// import styles from './CalendarBookingTooltip.scss'

export interface PopoutState {
  booking: Booking;
  left: number;
  bottom: number;
}
interface Props {
  state: PopoutState;
  members: Array<Member>;
  onEdit: () => void;
  onDelete: () => void;
}

const CalendarBookingTooltip: React.FC<Props> = props => {
  const { state, members } = props;
  const { booking, left, bottom } = state;
  const { deleted, report } = booking;
  const hasReport = report != null;
  const timeSinceEnd = dateHelpers.dateDiffDays(
    dateHelpers.parseBookingDate(booking.date_to),
    new Date(),
  );

  const classNames = ['info-box', booking.booking_type];
  if (deleted) {
    classNames.push('deleted');
  }

  let name = booking.who;
  if (booking.social) {
    name = `♥ ${name}`;
  }
  if (deleted) {
    name = `✖ ${name}`;
  }

  const range = dateHelpers.formatRange(
    dateHelpers.parseBookingDate(booking.date_from),
    dateHelpers.parseBookingDate(booking.date_to),
  );

  const memberWhoBooked = members.find(m => m.id === booking.member_id);
  let responsible = null;
  if (memberWhoBooked != null) {
    responsible = (
      <div className="responsible">
        Bokningsansvarig: {memberWhoBooked.name}
      </div>
    );
  }

  let crowd = pluralize(booking.num_people, {
    singular: 'person',
    plural: 'personer',
  });
  if (booking.num_non_members > 0) {
    crowd += `, varav ${pluralize(booking.num_non_members, {
      singular: 'gäst',
      plural: 'gäster',
    })}`;
  }

  const popoutStyle = {
    left,
    bottom,
  };

  let editAction: React.ReactElement | null = (
    <div className="edit" onClick={props.onEdit}>
      <a>Ändra</a>
    </div>
  );

  let deleteAction: React.ReactElement | null = (
    <div className="action delete" onClick={props.onDelete}>
      <a>Ta bort</a>
    </div>
  );

  const bookingIsNotForThisYear =
    booking.date_to_year !== new Date().getFullYear();
  const bookingIsReallyOld = booking.date_to_year < 2019;
  const bookingIsOld = bookingIsNotForThisYear || bookingIsReallyOld;
  if (bookingIsOld && hasReport) {
    editAction = null;
    deleteAction = null;
  }

  let rightAction: React.ReactElement | null = null;
  if (!deleted && !hasReport && timeSinceEnd > 0) {
    rightAction = (
      <div className="action-right">
        <a href={`/loggbok/${booking.id}`}>Avlägg rapport</a>
      </div>
    );
  }
  if (hasReport) {
    deleteAction = null;
    rightAction = (
      <div className="action-right">
        <a href={`/loggbok/${booking.id}`}>Rapport #{booking.id}</a>
      </div>
    );
  }

  return (
    <div className={classNames.join(' ')} style={popoutStyle}>
      <div className="top">
        <div className="name">{name}</div>
        <div className="daterange">{range}</div>
      </div>
      <div className="crowd">{crowd}</div>
      {responsible}
      <div className="description">{booking.description}</div>
      {deleted && (
        <div className="deleted-msg">
          Bokningen är borttagen, men är ändå poänggivande
        </div>
      )}
      <div className="bottom hasfloats">
        {editAction}
        {deleteAction}
        {rightAction}
      </div>
    </div>
  );
};
export default CalendarBookingTooltip;
