import type { Action } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { reportingApi } from 'apps/reporting/state/reporting.slice';
import sharedReducer from 'shared/state/shared.reducer';

const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [reportingApi.reducerPath]: reportingApi.reducer,
    shared: sharedReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(reportingApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;

// Deprecated types
export type Thunk<R = unknown> = ThunkReduxAction<R>;
export type ThunkReduxAction<R, S = AppState> = (
  dispatch: ThunkDispatch<S>,
  getState: () => S,
) => R;

export interface ThunkDispatch<S = AppState> {
  <A extends Action>(action: A): A;
  <R>(asyncAction: ThunkReduxAction<R, S>): R;
}
