import type {
  Member,
  Booking,
  BookingReport,
  CalendarEvent,
  BookingDraft,
  MemberPayload,
} from 'types/api';
import { deleteRequest, getRequest, postRequest, putRequest } from './base';

export default {
  members: {
    fetchAll(): Promise<Array<Member>> {
      return getRequest('/api/members');
    },
    saveMember(member: MemberPayload): Promise<Member> {
      if (member.id != null) {
        return putRequest(`/api/members/${member.id}`, { member });
      }
      return postRequest('/api/members', { member });
    },
    deleteMember(id: number): Promise<Member> {
      return deleteRequest(`/api/members/${id}`);
    },
  },
  bookings: {
    fetchUnreported(): Promise<Array<Booking>> {
      return getRequest('/api/bookings/unreported');
    },
    fetchByYear(year: number): Promise<Array<Booking>> {
      return getRequest(`/api/bookings?year=${year}`);
    },
    fetchById(id: number): Promise<Booking> {
      return getRequest(`/api/bookings/${id}`);
    },
    saveBooking(booking: BookingDraft): Promise<Booking> {
      let url = '/api/bookings';
      let method = postRequest;
      if (booking.id != null) {
        url = `${url}/${booking.id}`;
        method = putRequest;
      }
      return method(url, booking);
    },
    deleteBooking(id: number): Promise<void> {
      return deleteRequest(`/api/bookings/${id}`);
    },
  },
  events: {
    fetchByYear(year: number): Promise<Array<CalendarEvent>> {
      return getRequest(`/api/events?year=${year}`);
    },
  },
} as const;
