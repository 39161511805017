import React, { useCallback, useMemo } from 'react';
import { groupBy, pick } from 'lodash';
import type { Member } from 'types/api';

import styles from './FamilyTree.module.scss';
import { useSelector } from 'react-redux';
import {
  getMembersById,
  makeGetDescendants,
} from 'shared/state/shared.selectors';

const PALETTE = [
  'f94144',
  'f3722c',
  'f9c74f',
  '90be6d',
  '43aa8b',
  '4d908e',
  '577590',
  '277da1',
];

export interface MemberBranch extends Member {
  children: Array<MemberBranch>;
  spouse: Member | null;
}

interface Props {
  members: Array<Member>;
  onClickMember: (member: Member) => void;
  filterString: string;
}

const FamilyTree: React.FC<Props> = ({
  members,
  onClickMember,
  filterString,
}) => {
  const getDescendants = useSelector(makeGetDescendants);
  const membersById = useSelector(getMembersById);
  const filteredMembers = useMemo(() => {
    if (filterString.length < 1) {
      return members;
    }
    return members.filter(member => {
      const spouse = member.spouse_id != null && membersById[member.spouse_id];
      const searchSpace = [
        member,
        spouse && getDescendants(spouse.id),
        getDescendants(member.id),
      ]
        .flat()
        .map(m => m !== false && m?.name)
        .filter((x): x is string => typeof x === 'string');
      return searchSpace.some(name =>
        name.toLocaleLowerCase().includes(filterString.toLocaleLowerCase()),
      );
    });
  }, [members, filterString, membersById, getDescendants]);

  const membersByParent = useMemo(
    () =>
      groupBy(
        filteredMembers.filter(m => m.parent_id != null),
        'parent_id',
      ),
    [filteredMembers],
  );
  const membersBySpouse = useMemo(
    () =>
      groupBy(
        filteredMembers.filter(m => m.spouse_id != null),
        'spouse_id',
      ),
    [filteredMembers],
  );

  // Sanity logging
  useMemo(() => {
    for (const [id, group] of Object.entries(membersBySpouse)) {
      if (group.length > 1) {
        console.error(
          'Warning: multiple spouses found for member %s: %s',
          id,
          JSON.stringify(group.map(m => pick(m, ['id', 'name', 'spouse_id']))),
        );
      }
    }
  }, [membersBySpouse]);

  const getBranch = useCallback(
    (member: Member): MemberBranch => {
      return {
        ...member,
        children: (membersByParent[member.id] ?? []).map(child =>
          getBranch(child),
        ),
        spouse: membersBySpouse[member.id]?.[0] ?? null,
      };
    },
    [membersByParent, membersBySpouse],
  );
  const roots = useMemo(
    () =>
      filteredMembers
        .filter(member => member.parent_id == null && member.spouse_id == null)
        .map(getBranch),
    [filteredMembers, getBranch],
  );

  // const countByLevel = useMemo(() => {
  //   const getCounts = (branches: Array<MemberBranch>): Array<number> => {
  //     let childCounts: Array<number> = [];
  //     if (branches.length > 0) {
  //       childCounts = getCounts(branches.flatMap(member => member.children));
  //       if (childCounts.length === 1 && childCounts[0] === 0) {
  //         childCounts = [];
  //       }
  //     }
  //     return [
  //       sum(branches.map(member => 1 + (member.spouse != null ? 1 : 0))),
  //       ...childCounts,
  //     ];
  //   };
  //   return getCounts(roots);
  // }, [roots]);

  return (
    <div className={styles.familyTree}>
      {roots.map(root => (
        <div className={styles.root} key={root.id}>
          <FamilyTreeBranch member={root} onClickMember={onClickMember} />
        </div>
      ))}
      {/* <div className={styles.statsLine}>
        {countByLevel.map((count, level) => (
          <div key={level} className={styles.item}>{`${count} ${
            count === 1 ? 'medlem' : 'medlemmar'
          }`}</div>
        ))}
      </div> */}
    </div>
  );
};
export default FamilyTree;

const FamilyTreeBranch = ({
  member,
  color,
  onClickMember,
}: {
  member: MemberBranch;
  color?: string;
  onClickMember: (member: Member) => void;
}) => {
  const { id: memberId, first_name, spouse, children } = member;
  return (
    <div
      key={memberId}
      className={styles.branchRow}
      style={
        {
          '--branch-color': color != null ? `#${color}` : undefined,
        } as React.CSSProperties
      }
    >
      <div className={styles.person} onClick={() => onClickMember(member)}>
        <div className={styles.personTextWrap}>
          {first_name}
          {spouse != null && (
            <span
              className={styles.spouse}
              onClick={e => {
                e.stopPropagation();
                onClickMember(spouse);
              }}
            >
              &nbsp;&amp;&nbsp;{spouse.first_name}
            </span>
          )}
        </div>
        {children.length > 0 && (
          <div className={styles.horizontalLineToDescendants} />
        )}
      </div>
      {children.length > 0 && (
        <div className={styles.descendants}>
          {children.map((child, childIndex) => (
            <div className={styles.descendant} key={child.id}>
              <div className={styles.verticalLineConnectingDescendants} />
              <FamilyTreeBranch
                member={child}
                color={color ?? PALETTE[childIndex % PALETTE.length]}
                onClickMember={onClickMember}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
