import classNames from 'classnames';
import type { ChangeEvent } from 'react';

import validateBooking from './validateBooking';
import { parseBookingDate, formatRange } from '../../shared/utils/dateHelpers';
import MemberSelect from '../../shared/components/MemberSelect';
import type { Member, BookingDraft } from '../../types/api';
import NumberInput from 'shared/components/NumberInput';
import React from 'react';
import { useEffectOnValueChange } from 'shared/hooks/useEffectOnValueChange';

interface Props {
  innerRef?: React.Ref<HTMLDivElement>;
  onClickDateInput?: () => void;
  booking: BookingDraft;
  isSaving: boolean;
  members: Array<Member>;
  onChange: (booking: BookingDraft) => void;
  onSave: () => void;
  onCancel: () => void;
  highlightDatesCount: number;
}

export const BookingForm = (props: Props) => {
  const {
    innerRef,
    onClickDateInput,
    booking,
    isSaving,
    members,
    onChange,
    onSave,
    onCancel,
    highlightDatesCount,
  } = props;
  const [validationFailed, setValidationFailed] = React.useState(false);
  const [highlightDates, setHighlightDates] = React.useState<boolean>(false);
  const highlightTimeout = React.useRef<number | null>(null);
  useEffectOnValueChange(highlightDatesCount, () => {
    if (highlightTimeout.current != null) {
      clearTimeout(highlightTimeout.current);
    }
    setHighlightDates(true);
    const timeout = window.setTimeout(() => {
      setHighlightDates(false);
      clearTimeout(timeout);
    }, 300);
    highlightTimeout.current = timeout;
  });

  const handleChange = (update: Partial<BookingDraft>) => {
    onChange({
      ...booking,
      ...update,
    });
    setValidationFailed(false);
  };

  const handleChangeMemberId = (memberId: number | null) => {
    let who = booking.who;
    const chosenMember = members.find(m => m.id === memberId);
    if (who.length === 0 && chosenMember != null) {
      who = chosenMember.name.split(' ')[0];
    }
    handleChange({
      member_id: memberId,
      who,
    });
  };

  const handleSave = () => {
    const validation = validateBooking(booking);

    if (validation.success) {
      onSave();
    } else {
      setValidationFailed(true);
    }
  };

  const { date_from, date_to } = booking;

  let social = booking.social;
  let socialDisabled = false;
  if (booking.booking_type === 'exclusive') {
    social = false;
    socialDisabled = true;
  }

  let dates = 'Klicka i kalendern';
  if (date_from.length > 0 && date_to.length > 0) {
    const from = parseBookingDate(date_from);
    const to = parseBookingDate(date_to);
    dates = formatRange(from, to);
  }

  const cancelButtonStyle = {
    display: 'none',
  };

  let title = 'Boka';
  let buttonLabel = 'Skapa bokning';
  if (booking.id != null) {
    title = 'Ändra bokning';
    buttonLabel = 'Spara';
    cancelButtonStyle.display = '';
  }

  function handleClickDates() {
    if (onClickDateInput) {
      onClickDateInput();
    }
  }

  const validationResults = validateBooking(booking);
  const classNameWithValidation = (name: string): string => {
    if (validationFailed && validationResults.invalidFields.includes(name)) {
      return 'validation-error';
    }
    return '';
  };

  return (
    <div
      className={classNames('form', {
        isEditingExisting: booking.id != null,
      })}
      ref={innerRef}
    >
      <div className="title">{title}</div>

      <form className="booking">
        <div className="group">
          <div className="section">
            <label className="bold" htmlFor="member_id">
              Bokningsansvarig
            </label>
            <MemberSelect
              value={booking.member_id}
              members={members}
              onChange={handleChangeMemberId}
              className={classNameWithValidation('member_id')}
              minAge={12}
            />
          </div>
          <div className="section">
            <label className="bold" htmlFor="who">
              Vilka
            </label>
            <Input
              id="who"
              type="text"
              placeholder="Förnamn"
              maxLength={30}
              value={booking.who}
              onChange={who => handleChange({ who })}
              className={classNameWithValidation('who')}
            />
          </div>
        </div>
        <div
          className={classNames('section', 'dates', {
            highlight: highlightDates,
          })}
          onClick={handleClickDates}
        >
          <div className="bold">Datum</div>
          <span>{dates}</span>
        </div>
      </form>
      <div className="group">
        <Input
          name="booking_type"
          type="radio"
          id="type_speculation"
          checked={booking.booking_type === 'speculation'}
          onChange={() => handleChange({ booking_type: 'speculation' })}
        />
        <label className="inline" htmlFor="type_speculation">
          Spekulation
        </label>
        <br />
        <Input
          name="booking_type"
          type="radio"
          id="type_planned"
          checked={booking.booking_type === 'planned'}
          onChange={() => handleChange({ booking_type: 'planned' })}
        />
        <label className="inline" htmlFor="type_planned">
          Planerad vistelse
        </label>
        <br />
        <Input
          name="booking_type"
          type="radio"
          id="type_exclusive"
          checked={booking.booking_type === 'exclusive'}
          onChange={() => handleChange({ booking_type: 'exclusive' })}
        />
        <label className="inline" htmlFor="type_exclusive">
          Exklusiv bokning
        </label>
      </div>

      <div className="group">
        <Input
          id="social"
          type="checkbox"
          checked={social}
          disabled={socialDisabled}
          onChange={() => handleChange({ social: !booking.social })}
        />
        <label className="inline" htmlFor="social">
          {booking.num_people > 1 ? 'Vi' : 'Jag'} vill gärna ha sällskap
        </label>
      </div>

      <div className="group units-row">
        <div className="unit-50">
          <label className="bold" htmlFor="num_people">
            Antal
            <br />
            personer
            <NumberInput
              id="num_people"
              value={booking.num_people}
              onChange={value =>
                handleChange({
                  num_people: value,
                  num_non_members: Math.min(booking.num_non_members, value),
                })
              }
              className={classNameWithValidation('num_people')}
              minValue={1}
            />
          </label>
        </div>
        <div className="unit-50">
          <label className="bold" htmlFor="num_non_members">
            varav
            <br />
            gäster
            <NumberInput
              id="num_non_members"
              value={booking.num_non_members}
              onChange={value => handleChange({ num_non_members: value })}
              className={classNameWithValidation('num_non_members')}
              maxValue={booking.num_people}
            />
          </label>
        </div>
      </div>
      <div className="group">
        <label className="bold" htmlFor="description">
          Mer information
        </label>
        <textarea
          id="description"
          value={booking.description}
          className={classNameWithValidation('description')}
          onChange={e => handleChange({ description: e.target.value })}
        />
      </div>
      <div className="group">
        <button
          type="submit"
          className="btn btn-blue"
          disabled={isSaving}
          onClick={handleSave}
        >
          {buttonLabel}
        </button>
        <button
          type="button"
          className="btn cancel"
          disabled={isSaving}
          style={cancelButtonStyle}
          onClick={onCancel}
        >
          Avbryt
        </button>
      </div>
    </div>
  );
};

type InputProps = {
  onChange?: (value: string) => void;
} & Omit<React.ComponentProps<'input'>, 'onChange'>;
const Input = (props: InputProps) => (
  <input
    {...props}
    onChange={(e: ChangeEvent<HTMLInputElement>) =>
      props.onChange?.(e.target.value)
    }
  />
);

export default BookingForm;
