import * as React from 'react';

import BookingSummary from './BookingSummary';
import type { Booking, Member } from '../../../types/api';

interface Props {
  members: Array<Member>;
  bookings: Array<Booking>;
  onSelect: (id: number | null) => void;
}

export default class BookingList extends React.Component<Props> {
  renderBooking = (booking: Booking) => {
    const { members, onSelect } = this.props;
    let title = null;
    if (booking.id != null && booking.report != null) {
      title = `Rapport #${booking.id}`;
    }
    return (
      <BookingSummary
        key={booking.id}
        title={title}
        members={members}
        booking={booking}
        onSelect={onSelect}
      />
    );
  };

  render() {
    return (
      <div className="booking-list">
        {this.props.bookings.map(this.renderBooking)}
      </div>
    );
  }
}
