import axios, { type AxiosResponse, type AxiosRequestConfig } from 'axios';

export const getRequest = (url: string, config?: AxiosRequestConfig) => {
  return handleError(axios.get(url, extendAxiosConfig(config)));
};

export const postRequest = (
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig,
) => {
  return handleError(axios.post(url, data, extendAxiosConfig(config)));
};

export const putRequest = (
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig,
) => {
  return handleError(axios.put(url, data, extendAxiosConfig(config)));
};

export const deleteRequest = (url: string, config?: AxiosRequestConfig) => {
  return handleError(axios.delete(url, extendAxiosConfig(config)));
};

function extendAxiosConfig<TConfig>(
  config: AxiosRequestConfig<TConfig> | undefined,
): AxiosRequestConfig<TConfig> {
  return {
    ...config,
    headers: {
      ...config?.headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
}

export class ValidationError extends Error {
  public errors: Record<string, Array<string>>;
  public messages: Array<string>;
  constructor(errors: Record<string, Array<string>>, messages: Array<string>) {
    super('Validation error');
    this.errors = errors;
    this.messages = messages;
  }
}
function handleError<T>(promise: Promise<AxiosResponse<T>>): Promise<T> {
  return promise.then(
    response => response.data,
    error => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          window.location.reload();
        }
        const data = error.response?.data;
        if (
          error.response?.status === 422 &&
          typeof data?.errors === 'object' &&
          Array.isArray(data?.messages)
        ) {
          throw new ValidationError(data.errors, data.messages);
        }
      }
      return Promise.reject(error);
    },
  );
}
