import React from 'react';
import { useSelector } from 'react-redux';
import BookingList from './components/BookingList';
import { getMembers } from 'shared/state/shared.selectors';
import { useGetUnreportedBookingsQuery } from './state/reporting.slice';
import { useNavigate } from 'react-router-dom';

const UnreportedBookingsRoute: React.FC = props => {
  const members = useSelector(getMembers);
  const { data: unreportedBookings = [], isFetching } =
    useGetUnreportedBookingsQuery();
  const navigate = useNavigate();

  const allDone = !isFetching && unreportedBookings.length < 1;
  const showLoading = isFetching && unreportedBookings.length < 1;
  return (
    <div>
      <h1>Att rapportera</h1>
      {showLoading && 'Laddar...'}
      <div className="main">
        {allDone ? (
          'Alla bokningar är rapporterade.'
        ) : (
          <BookingList
            bookings={unreportedBookings}
            members={members}
            onSelect={id => navigate(`/loggbok/${id == null ? '' : id}`)}
          />
        )}
      </div>
    </div>
  );
};

export default UnreportedBookingsRoute;
