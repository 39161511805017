import { useEffect } from 'react';
import { usePrevious } from './usePrevious';
import { useMountEffect } from './useMountEffect';
import { isEqual } from 'lodash';

export const useEffectOnValueChange = <T>(
  value: T,
  callback: () => void,
  runOnMount = false,
) => {
  const prevValue = usePrevious(value);
  useEffect(() => {
    if (!isEqual(value, prevValue)) {
      callback();
    }
  });
  useMountEffect(() => {
    if (runOnMount) {
      callback();
    }
  });
};
