import * as React from 'react';

import MemberSelect from '../../../shared/components/MemberSelect';
import Dropdown from '../../../shared/components/Dropdown';
import Tooltip from '../../../shared/components/Tooltip';
import { calculateMemberAge } from '../logic/calculateCosts';

import type { Member, ReportParticipant } from '../../../types/api';
import NumberInput from 'shared/components/NumberInput';

interface Props {
  members: Array<Member>;
  participant: ReportParticipant;
  onChange: (a: ReportParticipant) => void;
  onRemove: () => void;
  startDate: string;
  index: number;
  payingMemberPresent: boolean;
}

type MemberTypeState = null | 'member' | 'guest';
interface State {
  type: MemberTypeState;
}

const participantTypeOptions: Array<{
  title: string;
  value: 'member' | 'guest';
}> = [
  { title: 'Medlem', value: 'member' },
  { title: 'Gäst', value: 'guest' },
];

export default class ReportParticipantForm extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    let type: MemberTypeState = null;
    if (props.participant.member_id != null) {
      type = 'member';
    } else if (props.participant.guest_name != null) {
      type = 'guest';
    }

    this.state = {
      type,
    };
  }

  set = (participantDiff: Partial<ReportParticipant>) => {
    this.props.onChange({
      ...this.props.participant,
      ...participantDiff,
    });
  };

  onChangeMember = (memberId: number | null) => {
    const member = this.props.members.find(m => m.id === memberId);
    let age = null;
    let member_is_paying = this.props.participant.member_is_paying;
    if (member != null) {
      age = calculateMemberAge(member, this.props.startDate);
      member_is_paying = member.is_paying || false;
    }
    this.set({ member_id: memberId, guest_name: null, age, member_is_paying });
  };

  onChangeGuestName = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: null | string = e.target.value;
    if (value.length < 1) {
      value = null;
    }
    this.set({ guest_name: value, member_id: null, member_is_paying: false });
  };

  onChangeAge = (numericalValue: number) => {
    this.set({ age: numericalValue });
  };

  onChangeNumNights = (numericalValue: number) => {
    this.set({ num_nights: numericalValue });
  };

  onChangeNumBoatRides = (numericalValue: number) => {
    this.set({ num_boat_rides: numericalValue });
  };

  onChangePayInsteadOfPoints = (v: { value: boolean } | null) => {
    this.set({
      pay_instead_of_points: v?.value || false,
    });
  };

  onChangeIsPayingMember = (v: { value: boolean } | null) => {
    this.set({
      member_is_paying: v?.value || false,
      num_boat_rides: 0,
    });
  };

  onChangeType = (option: { value: 'member' | 'guest' } | null) => {
    const type = option?.value || null;
    this.setState({ type }, () => {
      this.set({
        member_id: null,
        guest_name: null,
        member_is_paying: null,
        age: null,
        num_boat_rides: 0,
      });
    });
  };

  renderIdentifyingFields() {
    const { type } = this.state;
    const { participant, members } = this.props;
    const { member_id, guest_name, age } = participant;

    let memberIdField = null;
    let guestNameField = null;
    if (type === 'member') {
      memberIdField = (
        <div className="report-participant-column">
          <label>Medlem</label>
          <MemberSelect
            members={members}
            value={member_id}
            onChange={this.onChangeMember}
            isClearable
            disabled={guest_name != null}
          />
        </div>
      );
    } else if (type === 'guest') {
      guestNameField = (
        <div className="report-participant-column">
          <label>Gästens namn</label>
          <input
            style={{ width: '100%' }}
            type="text"
            placeholder="Förnamn Efternamn"
            value={guest_name || ''}
            onChange={this.onChangeGuestName}
            disabled={member_id != null}
          />
        </div>
      );
    }

    let ageInput = (
      <NumberInput
        style={{ width: '100%' }}
        value={age}
        onChange={this.onChangeAge}
      />
    );
    if (member_id != null) {
      ageInput = (
        <Tooltip content="Åldern beräknas efter medlemmens födelsedatum i medlemslistan">
          <input
            style={{ width: '100%' }}
            type="text"
            disabled
            value={age == null ? '18 (???)' : String(age)}
          />
        </Tooltip>
      );
    }

    const ageField = (
      <div className="report-participant-column" key={'age'}>
        <label>Ålder</label>
        {ageInput}
      </div>
    );

    return (
      <div className="report-participant-row">
        <div className="report-participant-column">
          <label>Resenärstyp</label>
          <Dropdown
            options={participantTypeOptions}
            value={this.state.type}
            onChange={this.onChangeType}
            titleMapper={x => x.title}
            valueMapper={x => x.value}
          />
        </div>
        {memberIdField}
        {guestNameField}
        {ageField}
      </div>
    );
  }

  renderPropertyFields() {
    const { type } = this.state;
    const { participant, members, payingMemberPresent } = this.props;
    const {
      member_id,
      num_nights,
      num_boat_rides,
      pay_instead_of_points,
      member_is_paying,
    } = participant;

    const age = participant.age != null ? participant.age : 18;

    let member = null;
    if (member_id != null) {
      member = members.find(m => m.id === member_id);
    }

    let numBoatRidesField = null;
    if (
      type === 'guest' ||
      (member != null && !member_is_paying && age >= 18)
    ) {
      numBoatRidesField = (
        <div className="report-participant-column" key={'num_boat_rides'}>
          <label>Antal båtskjutsar</label>
          <NumberInput
            style={{ width: '100%' }}
            value={num_boat_rides}
            onChange={this.onChangeNumBoatRides}
          />
        </div>
      );
    }

    let payOrPointsField = null;
    if (type === 'guest' && payingMemberPresent && age >= 12) {
      payOrPointsField = (
        <div className="report-participant-column">
          <label>Poängräkning</label>
          <Dropdown
            options={[
              { label: 'Poäng', value: false, selectValue: 'points' },
              { label: '200kr per natt', value: true, selectValue: 'cash' },
            ]}
            valueMapper={x => x.selectValue}
            titleMapper={x => x.label}
            value={pay_instead_of_points ? 'points' : 'cash'}
            onChange={this.onChangePayInsteadOfPoints}
          />
        </div>
      );
    }

    let isPayingField = null;
    if (type === 'member') {
      if (participant.age != null && participant.age < 18) {
        isPayingField = (
          <div className="report-participant-column">
            <label>Medlemstyp</label>
            <input
              style={{ width: '100%' }}
              type="text"
              disabled
              value="Under 18 år: gratis"
            />
          </div>
        );
      } else {
        isPayingField = (
          <div className="report-participant-column">
            <label>Medlemstyp</label>
            <Dropdown
              options={[
                { label: 'Betalande', value: true, selectValue: 'paying' },
                {
                  label: 'Ej betalande',
                  value: false,
                  selectValue: 'non-paying',
                },
              ]}
              valueMapper={x => x.selectValue}
              titleMapper={x => x.label}
              value={member_is_paying ? 'paying' : 'non-paying'}
              onChange={this.onChangeIsPayingMember}
            />
          </div>
        );
      }
    }

    return (
      <div className="report-participant-row">
        {payOrPointsField}
        {isPayingField}
        <div className="report-participant-column">
          <label>Antal övernattningar</label>
          <NumberInput
            style={{ width: '100%' }}
            value={num_nights}
            onChange={this.onChangeNumNights}
          />
        </div>
        {numBoatRidesField}
      </div>
    );
  }

  getName() {
    const { participant, members } = this.props;
    const { guest_name, member_id } = participant;
    let member = null;
    if (member_id != null) {
      member = members.find(m => m.id === member_id);
    }
    if (member != null) {
      return member.name;
    }
    if (guest_name != null) {
      return guest_name;
    }

    return null;
  }

  render() {
    const { index } = this.props;
    let title = `Resenär #${index + 1}`;
    const name = this.getName();
    if (name != null) {
      title = `${title} - ${name}`;
    }
    return (
      <div className="report-participant">
        <h3>
          {title}
          <a className="delete" onClick={this.props.onRemove}>
            Ta bort
          </a>
        </h3>
        {this.renderIdentifyingFields()}
        {this.renderPropertyFields()}
      </div>
    );
  }
}
