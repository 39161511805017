import { useEffect } from 'react';

/**
 * Syntactic sugar to run an effect on mount without having to pass an empty dependency array.
 */
export const useMountEffect = (callback: () => void) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on mount
  useEffect(() => {
    const result = callback();
    if (typeof result === 'function') {
      return result;
    }
  }, []);
};

/**
 * Syntactic sugar to run an effect on unmount without having to pass an empty dependency array.
 */
export const useUnmountEffect = (callback: () => void) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on unmount
  useEffect(() => callback, []);
};
