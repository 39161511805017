import * as React from 'react';

import ReportSummary from './ReportSummary';
import ReportParticipantForm from './ReportParticipantForm';
import * as dateHelpers from '../../../shared/utils/dateHelpers';
import MemberSelect from '../../../shared/components/MemberSelect';

import type {
  Booking,
  Member,
  ReportParticipant,
  BookingReport,
} from '../../../types/api';
import { createParticipantFromBooking } from '../logic/reportDraft.logic';

interface Props {
  bookingId: number;
  booking: Booking;
  report: BookingReport;
  members: Array<Member>;
  onSave: () => void;
  onCancel: () => void;
  onUpdateDraft: (draftChange: Partial<BookingReport>) => void;
}

interface State {
  validationFailed: boolean;
}

const bookingTypeMap = {
  speculation: 'Spekulation',
  planned: 'Planerad vistelse',
  exclusive: 'Exklusiv bokning',
};

export default class ReportingForm extends React.Component<Props, State> {
  updateDraft = (draft: Partial<BookingReport>) => {
    const { report, onUpdateDraft } = this.props;
    const changeset = { ...draft };
    if (
      report.accompanying_member_id != null &&
      changeset.report_participants?.some(p => !!p.member_is_paying)
    ) {
      changeset.accompanying_member_id = null;
    }
    onUpdateDraft(changeset);
  };
  handleAddParticipant = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const { booking, report } = this.props;
    const { report_participants } = report;
    this.updateDraft({
      report_participants: report_participants.concat([
        createParticipantFromBooking(booking),
      ]),
    });
  };

  renderBookingSummary = () => {
    const { booking, members } = this.props;

    const owner = members.find(m => m.id === booking.member_id);
    if (owner == null) {
      return null;
    }

    const row = (heading: string, value: string | number) => (
      <tr key={heading}>
        <th>{heading}</th>
        <td>{value}</td>
      </tr>
    );

    const dateRange = dateHelpers.formatRange(
      dateHelpers.parseBookingDate(booking.date_from),
      dateHelpers.parseBookingDate(booking.date_to),
      { alwaysIncludeYear: true },
    );

    const rows = [
      row('Bokningsansvarig', owner.name),
      row('Bokningstyp', bookingTypeMap[booking.booking_type]),
      row('Resenärer', booking.who),
      row('Datum', dateRange),
      row('Antal medlemmar', booking.num_people - booking.num_non_members),
      row('Antal ej medlemmar', booking.num_non_members),
      row('Egen beskrivning', booking.description),
    ];

    return (
      <table className="summary">
        <tbody>{rows}</tbody>
      </table>
    );
  };

  renderParticipant = (
    participant: ReportParticipant,
    index: number,
    payingMemberPresent: boolean,
  ) => {
    const { members, booking, report } = this.props;
    const { report_participants } = report;
    const onChange = (newParticipant: ReportParticipant) => {
      this.updateDraft({
        report_participants: [
          ...report_participants.slice(0, index),
          newParticipant,
          ...report_participants.slice(index + 1),
        ],
      });
    };
    const onRemove = () => {
      this.updateDraft({
        report_participants: [
          ...report_participants.slice(0, index),
          ...report_participants.slice(index + 1),
        ],
      });
    };
    return (
      <ReportParticipantForm
        key={index}
        participant={participant}
        members={members}
        onChange={onChange}
        onRemove={onRemove}
        startDate={booking.date_from}
        index={index}
        payingMemberPresent={payingMemberPresent}
      />
    );
  };

  renderAccompanyingMember = () => {
    const { members, report } = this.props;
    const { accompanying_member_id, report_participants } = report;
    if (report_participants.some(p => !!p.member_is_paying)) {
      return null; // If we have a paying member, hide field
    }
    return (
      <div className="section">
        <label>
          Ingen resenär är betalande medlem. Var ni i någon betalande medlems
          sällskap?
        </label>
        <div style={{ maxWidth: 200 }}>
          <MemberSelect
            members={members.filter(m => m.is_paying)}
            value={accompanying_member_id}
            onChange={id => this.updateDraft({ accompanying_member_id: id })}
            isClearable
          />
        </div>
      </div>
    );
  };

  render() {
    const { members, report, booking, onSave, onCancel } = this.props;
    const { report_participants, comment, accompanying_member_id } = report;
    const { num_free_nights } = booking;
    const completeParticipants = report_participants.filter(participantIsValid);
    const payingMemberPresent =
      report_participants.some(p => !!p.member_is_paying) ||
      accompanying_member_id != null;
    const submitDisabled =
      completeParticipants.length !== report_participants.length;
    return (
      <div>
        {this.renderBookingSummary()}
        <form className="booking">
          <h1>Rapport</h1>
          {report_participants.map((e, i) =>
            this.renderParticipant(e, i, payingMemberPresent),
          )}

          <div className="report-participant">
            <a className="btn" onClick={this.handleAddParticipant}>
              Lägg till resenär
            </a>
          </div>

          {this.renderAccompanyingMember()}

          <div className="section">
            <label>Kommentar</label>
            <textarea
              placeholder="Extra information till kassör och styrelse. Om poäng ska fördelas till någon annan än bokningsansvarig, skriv det här."
              onChange={e => this.updateDraft({ comment: e.target.value })}
              value={comment}
            />
          </div>
        </form>
        <div className="report-preview">
          <ReportSummary
            participants={completeParticipants}
            members={members}
            comment={comment}
            accompanyingMemberId={accompanying_member_id}
            numFreeNights={num_free_nights}
          />
        </div>

        <button
          className="btn btn-blue"
          type="submit"
          disabled={submitDisabled}
          onClick={onSave}
        >
          Spara
        </button>
        {report?.id != null ? (
          <button type="button" className="btn" onClick={onCancel}>
            Avbryt
          </button>
        ) : null}
      </div>
    );
  }
}

const participantIsValid = (e: ReportParticipant) => {
  if (e.member_id == null && e.guest_name == null) {
    return false;
  }

  return true;
};
