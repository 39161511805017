import uniq from 'lodash/uniq';

import { parseBookingDate } from '../../shared/utils/dateHelpers';

import type { Booking } from '../../types/api';
import { orderBy, sortBy } from 'lodash';

export function makeCalendarForMonth(
  year: number,
  month: number,
): Array<Array<Date>> {
  const rows: Array<Array<Date>> = [];

  const firstInMonth = new Date(year, month);
  let daysBeforeFirstInMonth = firstInMonth.getDay() - 1;
  if (daysBeforeFirstInMonth < 0) {
    daysBeforeFirstInMonth = 7 + daysBeforeFirstInMonth;
  }

  let currentRow: Array<Date> = [];
  const add = (date: Date) => {
    currentRow.push(date);

    if (currentRow.length >= 7) {
      rows.push(currentRow);
      currentRow = [];
    }
  };
  for (let start = 1 - daysBeforeFirstInMonth, i = start; i <= 42; i += 1) {
    const day = new Date(year, month, i);
    add(day);
  }

  return rows;
}

export interface ScheduleEntry {
  booking: Booking;
  startIndex: number;
  endIndex: number;
}

export interface ScheduleWeekMap {
  [startDayIndex: number]: ScheduleEntry;
}

export function makeScheduleForWeek(
  weekStart: Date,
  allBookings: Array<Booking>,
): Array<ScheduleWeekMap> {
  const rowStart = weekStart;
  const rowEnd = new Date(weekStart);
  rowEnd.setDate(rowEnd.getDate() + 6);

  if (!Array.isArray(allBookings)) {
    throw new Error(
      `allBookings: expected Array. Instead got ${typeof allBookings}: ${JSON.stringify(
        allBookings,
      )}`,
    );
  }

  // Only those relevant
  let bookings = allBookings.filter(b => {
    const inRange = (x: Date) => rowStart <= x && x <= rowEnd;
    const superRange = (x: Date, y: Date) => x <= rowStart && rowEnd <= y;

    const dateFrom = parseBookingDate(b.date_from);
    const dateTo = parseBookingDate(b.date_to);

    return inRange(dateFrom) || inRange(dateTo) || superRange(dateFrom, dateTo);
  });

  if (bookings.length < 1) {
    return [];
  }

  // Sort by size - longest bookings at top
  const getBookingLengthInRow = (x: Booking) => {
    let dateFrom = parseBookingDate(x.date_from);
    if (dateFrom < rowStart) {
      dateFrom = rowStart;
    }
    let dateTo = parseBookingDate(x.date_to);
    if (dateTo > rowEnd) {
      dateTo = rowEnd;
    }
    return +dateTo - +dateFrom;
  };
  bookings = orderBy(
    bookings,
    [
      booking => !booking.deleted,
      getBookingLengthInRow,
      booking => booking.report != null,
      booking => booking.booking_type !== 'speculation',
      booking => booking.num_people,
    ],
    ['desc', 'desc', 'desc', 'desc', 'desc'],
  );

  const levels: { [level: number]: Array<number> } = {};
  const bookingMapsByLevel: {
    [level: number]: ScheduleWeekMap;
  } = {};
  for (const b of bookings) {
    // Find out which indices (which days)
    // of the week the booking will take up
    const indices: Array<number> = [];
    for (let i = 0; i < 7; i += 1) {
      const day = new Date(rowStart);
      day.setDate(day.getDate() + i);

      const dateFrom = parseBookingDate(b.date_from);
      const dateTo = parseBookingDate(b.date_to);
      const isOnDateFrom = dateFrom.getTime() === day.getTime();
      const isOnDateTo = dateTo.getTime() === day.getTime();
      const isOnOneDayBooking = isOnDateFrom && isOnDateTo;
      if ((dateFrom < day && day < dateTo) || isOnOneDayBooking) {
        indices.push(i * 2, i * 2 + 1);
      } else if (isOnDateFrom) {
        indices.push(i * 2 + 1);
      } else if (isOnDateTo) {
        indices.push(i * 2);
      }
    }

    const startIndex = Math.min(...indices);
    const endIndex = Math.max(...indices);

    let level = 0;
    const findLevel = () => {
      const result = [];
      for (let idx = 0; idx < indices.length; idx += 1) {
        const i = indices[idx];
        if (levels[level] != null ? levels[level].includes(i) : undefined) {
          level += 1;
          findLevel();
          break;
        }

        result.push(undefined);
      }
      return result;
    };
    findLevel();

    if (levels[level] == null) {
      levels[level] = [];
    }
    levels[level] = uniq(levels[level].concat(indices));

    if (bookingMapsByLevel[level] == null) {
      bookingMapsByLevel[level] = {};
    }
    bookingMapsByLevel[level][startIndex] = {
      booking: b,
      startIndex,
      endIndex,
    };
  }

  const levelBookings: Array<ScheduleWeekMap> =
    Object.values(bookingMapsByLevel);

  // What is the reason for this?
  // levelBookings.sort((a, b) => {
  //   const delta = (x: ScheduleWeekMap) =>
  //     Object.values(x).reduce(
  //       (sum: number, y: ScheduleEntry) =>
  //         sum + (y.endIndex - y.startIndex) + 1,
  //       0,
  //     );
  //   return delta(b) - delta(a);
  // });

  return levelBookings;
}
