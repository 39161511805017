import range from 'lodash/range';
import type { Booking, ReportParticipant, Member } from 'types/api';
import { parseBookingDate, dateDiffDays } from 'shared/utils/dateHelpers';
import { calculateMemberAge } from './calculateCosts';

export const createInitialParticipantsForBooking = (
  booking: Booking,
  members: Array<Member>,
): Array<ReportParticipant> => {
  const booker = members.find(m => m.id === booking.member_id);
  let bookerIsPaying = false;
  let bookerAge = null;
  if (booker != null) {
    bookerIsPaying = booker.is_paying || false;
    bookerAge = calculateMemberAge(booker, booking.date_from);
  }
  const bookerParticipant: ReportParticipant = {
    ...createParticipantFromBooking(booking),
    member_id: booking.member_id,
    age: bookerAge,
    member_is_paying: bookerIsPaying,
  };

  const numPeople = booking.num_people;
  const numMembers = Math.max(1, numPeople - booking.num_non_members);
  const numGuests = numPeople - numMembers;
  const participants: Array<ReportParticipant> = range(
    numMembers + numGuests - 1,
  ).map(() => createParticipantFromBooking(booking));
  return [bookerParticipant, ...participants];
};

export const createParticipantFromBooking = (
  booking: Booking,
): ReportParticipant => {
  const dateFrom = parseBookingDate(booking.date_from);
  const dateTo = parseBookingDate(booking.date_to);
  const baseNumNights = dateDiffDays(dateFrom, dateTo);

  let baseNumBoatRides = 0;
  const bookingMonth = dateTo.getMonth() + 1;
  if (bookingMonth >= 5 && bookingMonth <= 10) {
    baseNumBoatRides = 2;
  }
  return {
    member_id: null,
    num_nights: baseNumNights,
    num_boat_rides: baseNumBoatRides,
    pay_instead_of_points: false,
    guest_name: null,
    age: null,
    member_is_paying: false,
  };
};
