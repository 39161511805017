import { createSelector } from 'reselect';
import { keyBy, orderBy } from 'lodash';
import { calculateMemberAge } from 'apps/reporting/logic/calculateCosts';
import type { Member } from 'types/api';
import type { AppState } from 'shared/setup/store';

const readSharedState = (state: AppState) => state.shared;

export const getMembers = createSelector([readSharedState], state =>
  orderBy(
    state.members,
    [m => calculateMemberAge(m) ?? 0, m => m.name],
    ['desc', 'asc'],
  ),
);

export const getNonDeletedMembers = createSelector([getMembers], members =>
  members.filter(m => !m.deleted),
);

export const makeGetDescendants = createSelector(
  [getNonDeletedMembers],
  members => {
    const descendantCache: Record<number, Array<Member>> = {};
    const getDescendants = (id: number): Array<Member> => {
      if (descendantCache[id]) {
        return descendantCache[id];
      }
      const directDescendants = members
        .filter(m => m.spouse_id === id)
        .concat(members.filter(m => m.parent_id === id));
      const descendants = directDescendants.flatMap(m => {
        const descendants = getDescendants(m.id);
        return [m, ...descendants];
      });
      descendantCache[id] = descendants;
      return descendants;
    };
    return getDescendants;
  },
);

export const getMembersById = createSelector([getNonDeletedMembers], members =>
  keyBy(members, 'id'),
);

export const getViableRelationsForMember = createSelector(
  [getNonDeletedMembers, makeGetDescendants],
  (members, getDescendants) => {
    const filtered = members.filter(m => m.spouse_id == null);

    const cache: Record<number, Array<Member>> = {};
    const calculate = (id: number): Array<Member> => {
      const descendants = getDescendants(id);
      return filtered.filter(m => m.id !== id && !descendants.includes(m));
    };
    // biome-ignore lint/suspicious/noAssignInExpressions: it's fine bruv
    return (memberId: number) => (cache[memberId] ??= calculate(memberId));
  },
);
